<template lang="html">

<div v-bind:class="{'slds-is-open': opened}" class="slds-section">
  <h3 class="slds-section__title">
    <button v-on:click="$emit('click')" aria-controls="expando-unique-id" aria-expanded="true" class="slds-button slds-section__title-action">
      <svg class="slds-section__title-action-icon slds-button__icon slds-button__icon_left" aria-hidden="true">
        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#switch"></use>
      </svg>
      <span class="slds-truncate" v-bind:title="title">{{title}}</span>
    </button>
  </h3>
  <div aria-hidden="false" class="slds-section__content" id="expando-unique-id">
    <slot></slot>
  </div>
</div>

</template>

<script>

export default {
  name: 'gnetExpandable',
  components: {
  },
  computed: {
  },
  props: {
    title: String,
    opened: Boolean
  },
  data: function () {
      return {
    }
  },
  mounted: function() {
  },
  async created() {

  },
  methods: {
  }
}
</script>

<style scoped>
</style>
