<template lang="html">

  <gnetModal v-bind:title="$t(title)" v-bind:object="object" v-on:cancel="$emit('cancel')" v-on:submit="$emit('submit')" v-bind:disabled="!durty">

    <div style="background-color: white;">
      <div style="">
        <gnetFormMask v-bind:large="true" v-on:click="$emit('togglesection',$event)" v-bind:sections="sections" 
          v-on:change="$emit('setfield',$event)" v-on:remove="$emit('remove',$event)" v-on:add="$emit('add',$event)"> </gnetFormMask>
      </div>
    </div>

  </gnetModal>

</template>

<script>
import gnetModal from '../components/modal.vue'
import gnetFormMask from '../components/formmask.vue'

export default {
  name: 'gnetCreate',
  components: {
    gnetModal,
    gnetFormMask
  },
  computed: {
  },
  props: {
    title: String,
    object: String,
    sections: Array,
    durty: Boolean
  },
  data() {
    return {
    }
  },
  mounted: function() {
  },
  async created() {

  },
  methods: {
  }
}
</script>

<style scoped>
</style>
