<template>
  <div class="home">

    <gnetHeader v-if="showheader" />

    <gnetTabBar
      v-bind:titoloapp="$store.getters.appname"
      v-bind:tabs="$store.state.tabs"
      v-bind:active_tab="$store.state.active_tab.name"
      v-on:click="changeTab($event)" />

    <zRecord v-bind:recordId="recordId" v-bind:objectName="objectName" v-bind:readonly="true" v-bind:headlineActions="headlineActions" 
      v-on:conferma="conferma" v-on:invalida="showInvalida = true" v-on:ready="getdocument($event)">
    </zRecord>

    <div v-if="this.thedocument && this.thedocument['Data_Caricamento__c']" class="slds-box slds-grid" style="background-color: white">
      <div class="slds-col slds-size_3-of-4">
        {{filename}}
      </div>
      <div class="slds-col slds-size_1-of-4">
        <button class="slds-button slds-button_brand" v-on:click="doTotp()">Scarica</button>
      </div>
      <a id="downloadfile" v-bind:href="filedata"  v-bind:download="filename" style="display:none" >
        Clicca qui per scaricare il file
      </a>
    </div>

    <gnetModal v-if="askTotp" v-bind:title="'Sblocco documento'" v-bind:object="'document'" v-on:cancel="clearTotp(false)" v-on:submit="clearTotp(true)" :disabled="codice == ''">
      <div class="slds-form-element" style="padding-bottom: 10px;">
        <label class="slds-form-element__label" for="form-element-totp">Inserire il codice di sblocco a tempo</label>
        <div class="slds-form-element__control">
          <input v-model="codice" type="text" id="form-element-totp" placeholder="codice..." class="slds-input" />
        </div>
      </div>
      <div class="slds-progress-bar" aria-valuemin="0" aria-valuemax="100" v-bind:aria-valuenow="seconds" aria-label="tempo rimasto" role="progressbar">
        <span class="slds-progress-bar__value" v-bind:style="{'width':seconds+'%'}" v-bind:class="{'slds-progress-bar__value_success':seconds<90}">
          <span class="slds-assistive-text">Progress: {{seconds}}%</span>
        </span>
      </div>      
    </gnetModal>

    <gnetModal v-if="showInvalida" :title="'Invalida documento'" :object="'document'" @cancel="showInvalida = false" @submit="invalida" :disabled="false">
      <div class="slds-form-element">
        <label class="slds-form-element__label" for="form-element-invalida">Causale Rifiuto</label>
        
        <div class="slds-form-element__control">
          <input v-model="crifiuto" type="text" id="form-element-invalida" placeholder="Motivazione del rifiuto..." class="slds-input" />
        </div>
      </div>
    </gnetModal>

    <gnetSpinner v-bind:busy="busy"></gnetSpinner>
  
  </div>
</template>

<script>
// @ is an alias to /src
// import numeral from 'numeral'
// import apiclient from '@/apiclient.js'
import apiclient from '@/apiclient.js'

import gnetMixLib from '@/mixin.js'

import gnetHeader from '../components/header.vue'
import gnetTabBar from '../components/tabbar.vue'
import gnetSpinner from '../components/spinner.vue'
import zRecord from '../components/zrecord.vue'
import gnetModal from '../components/modal.vue'
// import { clearInterval } from 'timers'
import { readMessage, decrypt, readKey, decryptKey } from 'openpgp';

export default {
  name: 'Form',
  mixins: [gnetMixLib],
  components: {
    gnetModal,
    gnetHeader,
    gnetTabBar,
    gnetSpinner,
    zRecord,
  },
  props: {
    objectxxx : String
  },
  data: function() {
    return {
      codice:'',
      askTotp: false,
      timer: {},
      seconds:0,
      recordId:'',
      objectName: '',
      related: [],
      showheader: true,
      playbook: '',
      record:{},
      busy: false,
      filedata:'',
      filename:'',
      scaricato:false,
      thedocument:{},
      headlineActions: [  ],

      showInvalida: false,
      crifiuto: '', // Causale_Rifiuto__c,
    };
  },
  created: function() {
  },
  mounted: function() {
    this.recordId=this.$route.params.id;
    this.objectName='documento__c';
    console.log('mounted');
    console.log(this.recordId);
    // this.viewPdf();
    this.getRecord();
  },
  methods: {
    invalida: function() {
      //if (confirm('Invalida documento?')) {
        var body={ Stato__c: 'Non valido', Causale_Rifiuto__c: this.crifiuto };
        apiclient.update(this.$store.getters.connection, "Documento__c", this.recordId, body).then( () =>{
          this.headlineActions=[ ];
          var tmp=this.recordId;
          this.recordId='';
          this.showInvalida = false
          this.$nextTick(()=>{
            this.recordId=tmp;
          })
          this.$router.push( { path: '/idoneita/'+ this.thedocument.Opportunita__c } )
        })
      // }
    },
    conferma: function() {
      var body={ Stato__c: 'Valido' };
      apiclient.update(this.$store.getters.connection, "Documento__c", this.recordId, body).then( () =>{
        this.headlineActions=[ ];
        var tmp=this.recordId;
        this.recordId='';
        this.$nextTick(()=>{
          this.recordId=tmp;
        })
        this.$router.push( { path: '/idoneita/'+ this.thedocument.Opportunita__c } )
      })
    },
    getdocument: function(rec) {
      this.thedocument=rec;
      if (this.thedocument.Data_Lettura__c && this.thedocument.Stato__c=='In attesa di validazione') {
        this.headlineActions=[ 
          {title: 'Conferma Validità', name:'conferma', symbol:'approval'},
          {title: 'Invalida Documento', name:'invalida', symbol:'cancel_file_request'} 
        ];
      }
    },
    doTotp: function() {
      this.askTotp=true;
      this.timer=setInterval( () => {
        var d=new Date();
        this.seconds=((d.getSeconds() % 30)+1)/30*100;
      }, 1000)
    },
    clearTotp: function(submit) {
      clearInterval(this.timer);
      console.log(submit);
      if (!submit) {
        this.askTotp=false;
        return;
      }
      apiclient.getcertificate(this.$store.getters.connection, this.$store.getters.connection.username, this.codice, this.recordId).then( async (data) => {
        this.busy=false;
        this.askTotp=false;
        console.log(data);
        if (!data.data) {
          alert('token non valido, riprovare');
          return;
        }
//        this.$store.commit('SET_CERT', data.data);

        var privateKey=await readKey({ armoredKey: data.data })
        var pass='super long and hard to guess secret';
        var privkey=await decryptKey({ privateKey: privateKey, passphrase: pass});

        var file=await this.getAttachment(this.record.Id)
        var msg=await readMessage({ armoredMessage: file  });
        var themsg=await decrypt({ message: msg, decryptionKeys: privkey });
        console.log(themsg);

        this.filedata='data:image/png;base64,'+themsg.data;
        this.$nextTick(function() {
          var body={ Data_Lettura__c: new Date() };
          apiclient.update(this.$store.getters.connection, "Documento__c", this.recordId, body).then( () =>{
            this.headlineActions=[ 
              {title: 'Conferma Validità', name:'conferma', symbol:'approval'},
              {title: 'Invalida Documento', name:'invalida', symbol:'cancel_file_request'} 
            ];
            var x=document.getElementById('downloadfile');
            x.click();
            var tmp=this.recordId;
            this.recordId='';
            this.$nextTick(()=>{
              this.recordId=tmp;
            })
          })
        })

      }).catch( (err) => {
        this.busy=false;
        console.log(err);
        alert('certificato scaduto');
      })

    },
    viewPdf: function() {
      console.log('viewPdf');
      var pdfjsLib = window['pdfjs-dist/build/pdf'];      

      var url = 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/examples/learning/helloworld.pdf';

      // The workerSrc property shall be specified.
      pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';

      var loadingTask = pdfjsLib.getDocument(url);
      loadingTask.promise.then(function(pdf) {
        console.log('PDF loaded');

        var pageNumber = 1;
        pdf.getPage(pageNumber).then(function(page) {

          console.log('Page loaded');
          
          var scale = 1.5;
          var viewport = page.getViewport({scale: scale});

          // Prepare canvas using PDF page dimensions
          var canvas = document.getElementById('the-canvas');
          var context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          // Render PDF page into canvas context
          var renderContext = {
            canvasContext: context,
            viewport: viewport
          };
          var renderTask = page.render(renderContext);
          renderTask.promise.then(function () {
            console.log('Page rendered');
          });

        });
    console.log('Page loaded');        
      } , function (reason) {
        // PDF loading error
        console.error(reason);
      });
    },
    getRecord: function() {
      var self=this;
      console.log('getrecord1');
      return new Promise(function(resolve, reject) {
        console.log('getrecord2');
        var soql='select id, name, body from attachment where parentid=\''+self.recordId+'\'';
        console.log(soql);
        apiclient.query(self.$store.getters.connection, soql).then(function(data) {
          console.log('records:'+data.length);
          console.log(data)
          if (data.length>0) {
            self.record=data[0];
            self.filename=self.record.Name;
          }
          self.busy=false;
          resolve();
        }).catch(function(err){
          console.log(err);
          reject(err);
        })
      })
    },
    getAttachment: function(id) {
      var self=this;
      return new Promise(function(resolve, reject) {
        console.log('getAttachment');
        apiclient.getattachmentblob(self.$store.getters.connection, id).then(function(data) {
          console.log('records:'+data.length);
          console.log(data)
          self.busy=false;
          resolve(data.data);
        }).catch(function(err){
          console.log(err);
          reject(err);
        })
      })
    },
  }
}
</script>
<style scoped>
</style>

