export default {
    methods: {
        add: function(sections,e) {
            var s=sections[e.section];
            var f=s.fields[e.field];
            if (f.type=='table') {
                var jv=this.deserialTable( f, f.value );
                jv.splice(jv.length,0,{});
                f.value=this.serialTable(f,jv);
            }
        },
        remove: function(sections,e) {
            var s=sections[e.section];
            var f=s.fields[e.field];
            if (f.type=='table') {
                var jv=this.deserialTable( f, f.value );
                jv.splice(e.index,1);
                f.value=this.serialTable(f,jv);
            }
        },
        toggleSection: function(sections,index) {
            sections[index].opened=!sections[index].opened;
        },
        setField: function(sections,e) {
            var s=sections[e.section];
            var f=s.fields[e.field];
            var v=e.value;
        
            if (f.type=='table') {
                var jv=this.deserialTable( f, f.value );
                var vnew={};
                if (v.section<0) {
                    jv.push( vnew );
                } else {
                    vnew=jv[v.section];
                }
                vnew[v.field]=v.value;
                f.value=this.serialTable(f,jv);
            } else {
                f.value=v;
            }
        },
        serialTable: function(f, v) {
            if (f.type=='table') {
              if (f.format=='json') {
                if (v) return JSON.stringify(v);
                return JSON.stringify([]);
              }
              if (f.format=='picklist') {
                if (v) return v.join(';');
                return v;
              }
            }
            return undefined;
        },
        deserialTable: function(f, v) {
            if (f.type=='table') {
              if (f.format=='json') {
                if (v) return JSON.parse(v);
                return [];
              }
              if (f.format=='picklist') {
                if (v) return v.split(';');
                return [];
              }
            }
            return undefined;
        },
        resolveField: function(object, fields, record) {
            fields.forEach(function(f) {
              var fdb=object.fields[ object.mfields[f.name] ];
              if (!fdb) {
                alert('field not found:'+f.name+' '+object.mfields[f.name] );
              }
              this.$set(f,'order',fdb.order);
              this.$set(f,'title',fdb.label);
              this.$set(f,'label',fdb.label);

              if (fdb.disabled) this.$set(f,'disabled',fdb.disabled);
              if (fdb.required) this.$set(f,'required',fdb.required);
              if (fdb.options) this.$set(f,'options',fdb.options);

              var t=fdb.type;
              switch (fdb.type) {
                case 'truncate':
                case 'string':
                  t='text';
                  break;
              }
              this.$set(f,'type',t);
              if (record) f.value=record[f.name];
            }, this )
        },
        checkAuth: function(authname) {
//            console.log('verifica autorizzazione:'+authname)
            if (!this.$store.getters.connection.userdata) return false;
            if (this.$store.getters.connection.userdata.includes(authname)) return true;
            return false;
        },
        changeTab: function(tabName) {
            console.log('tabName:');
            console.log(tabName);
            this.$store.commit('SET_ACTIVE_TAB', tabName);
            if (tabName.path)
                this.$router.push( { path: tabName.path } );
            else
                this.$router.push( { path: '/' } );
          },
      
        getFieldValue: function(obj, path) {
        // eslint-disable-next-line no-redeclare
        for (var i=0, path=path.split('.'), len=path.length; i<len; i++){
            if (obj)
            obj = obj[path[i]];
        }
        return obj;
        },
        updateRecordFields: function(sections) {
            sections.forEach(function(s) {
              this.updateFieldsValue(s.fields);
            }, this);
          },
        updateFieldsValue: function(fields) {
            fields.forEach(function(f) {
                this.$set(f,'value',this.getFieldValue(this.record,f.name) );
            }, this);
        },
        mapFields: function(mfields, fields) {
            // header
            fields.forEach(function(f) {
                if (f.type=='picklist')
                    mfields.set('toLabel('+f.name+')', f);
                else
                    mfields.set(f.name, f);
            });
        },
        uniqueFields: function(mfields) {
            var fields=[];
            var it=mfields.keys();
            var f=it.next();
            while (!f.done){
                fields.push(f.value);
                f=it.next();
            }
            return fields;
        },
    }
}