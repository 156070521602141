import winston from 'winston'

// import Elasticsearch from 'winston-elasticsearch'
// import { Client } from '@elastic/elasticsearch'
/*
const client = new Client({
  node: 'http://34.77.164.34:9200',
  maxRetries: 5,
  requestTimeout: 60000,
  sniffOnStart: true
})

var esTransportOpts = {
    level: 'error',
    index: 'splog',
    client: client,
    clientOpts: { node: "http://34.77.164.34:9200" }
};
*/
const logger= winston.createLogger({
    transports: [
        new winston.transports.Console({ level:'info'}),
        // new winston.transports.File({ filename:'error.log', level:'warn'}),
        // new Elasticsearch(esTransportOpts)
    ]
});


const gnlogger = {
    install(Vue) {
      Vue.prototype.$logger = logger;
    }
};
  
export default gnlogger;