<template lang="html">

  <div>
    <fieldset class="slds-form-element">
      <legend class="slds-form-element__legend slds-form-element__label">{{title}}</legend>

      <div class="slds-form-element__control" >
        <div class="slds-visual-picker slds-visual-picker_large" v-for="task in tasks" v-bind:key="task.name" v-on:click="$emit('click',task.name)">

          <label for="visual-picker-91">
            <span class="slds-visual-picker__figure slds-visual-picker__icon slds-align_absolute-center">

              <span class="slds-is-not-selected">
                <span class="slds-icon_container">
                  <svg class="slds-icon slds-icon-utility-connected_apps slds-icon_large slds-icon-text-default" aria-hidden="true">
                    <use v-bind:xlink:href="'/assets/icons/standard-sprite/svg/symbols.svg#'+task.symbol"></use>
                  </svg>
                </span>
              </span>
            </span>
            <span class="slds-visual-picker__body">
              <span class="slds-text-title">{{task.label}}</span>
              </span>
          </label>

        </div>
      </div>
    </fieldset>
  </div>

</template>

<script>

export default {
  name: 'empty',
  components: {
  },
  computed: {
  },
  props: {
    title: String,
    tasks: Array
  },
  data() {
    return {
    }
  },
  mounted: function() {
  },
  async created() {

  },
  methods: {
  }
}
</script>

<style scoped>
</style>
