<template lang="html">
  <section v-if="show" role="dialog" tabindex="-1" aria-labelledby="modal-heading-01" aria-modal="true" aria-describedby="modal-content-id-1" class="slds-modal slds-fade-in-open">
    <div class="slds-modal__container boxlogin">

      <header class="slds-modal__header">
        <button class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" title="Close">
          <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
          </svg>
          <span class="slds-assistive-text">Close</span>
        </button>
        <h2 id="modal-heading-01" class="slds-modal__title slds-hyphenate titolo">Login </h2>
      </header>

      <div class="slds-modal__content slds-p-around_medium" id="modal-content-id-1"> 
        <div>
          <div class="slds-form-element">
            <label class="slds-form-element__label" for="form-element-1"><abbr class="slds-required" title="required">* </abbr>Nome Utente</label>
            <div class="slds-form-element__control">
              <input v-model="username" type="text" id="form-element-03" placeholder="nome utente..." required="" class="slds-input" />
            </div>
          </div>

          <div class="slds-form-element">
            <label class="slds-form-element__label" for="form-element-1"><abbr class="slds-required" title="required">* </abbr>Password</label>
            <div class="slds-form-element__control">
              <input v-model="password" type="password" id="form-element-03" placeholder="password..." required="" class="slds-input" />
            </div>
          </div>
        </div>
      </div>

      <footer class="slds-modal__footer ">
        <button v-on:click="submit" class="slds-button slds-button_brand">Prosegui</button>
      </footer>

    </div>
  </section>

</template>

<script>
export default {
  name: 'login',
  data: function() {
    return {
      show:false,
      username:'',
      password:'',
    }
  },
  mounted: function() {
    var self=this;
    this.$store.dispatch('getLocalUser').then(function(userdata) {
      if (userdata) {
        console.log('got localstorage');
        console.log(userdata);
        self.$emit('submit', {username:userdata.username, password:userdata.password});
      } else {
        self.show=true;
      }
    }).catch(function(err) {
        self.show=true;
    })
  },
  methods: {
    close: function() {
    },
    submit: function() {
      this.$emit('submit', {username:this.username, password:this.password});
    }
  }
}
</script>

<style scoped>
.boxlogin {
  max-width: 28rem;
}
.titolo {
  font-weight:700;
  font-size: 20px;
}
</style>
