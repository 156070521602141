<template>
  <div class="home">

    <gnetHeader v-if="showheader" />

    <gnetTabBar
      v-bind:titoloapp="$store.getters.appname"
      v-bind:tabs="$store.state.tabs"
      v-bind:active_tab="$store.state.active_tab.name"
      v-on:click="changeTab($event)" />

    <gnetModal v-bind:wmode="true" v-bind:title="'Nuovo Certificato Digitale'" v-bind:object="'document'" v-bind:buttons="buttons" v-on:cancel="clearCert" v-on:click="doCert($event)">
      <span v-if="!secret">
        Si desidera procedere con la generazione di un nuovo certificato digitale?<br/>
        <br/>
        ATTENZIONE: la generazione di un nuovo certificato rende illeggibili tutti i documenti presenti in questa applicazione.<br/>
        <br/>
        QUESTA OPERAZIONE E' IRREVERSIBILE.<br/>
        <br/>
        PROCEDERE COMUNQUE?
      </span>

      <div v-if="secret" id="canvas" style="text-align: center;"></div>

    </gnetModal>

    <gnetSpinner v-bind:busy="busy"></gnetSpinner>
  
  </div>
</template>

<script>
// @ is an alias to /src
// import numeral from 'numeral'
// import apiclient from '@/apiclient.js'
import apiclient from '@/apiclient.js'

import gnetMixLib from '@/mixin.js'
import QRCodeStyling from 'qr-code-styling'

import gnetHeader from '../components/header.vue'
import gnetTabBar from '../components/tabbar.vue'
import gnetSpinner from '../components/spinner.vue'
import gnetModal from '../components/modal.vue'
// import { clearInterval } from 'timers'
import { readMessage, decrypt, readKey, decryptKey, generateKey } from 'openpgp';

export default {
  name: 'Certificato',
  mixins: [gnetMixLib],
  components: {
    gnetModal,
    gnetHeader,
    gnetTabBar,
    gnetSpinner,
  },
  props: {
    objectxxx : String
  },
  data: function() {
    return {
      codice:'',
      showheader: true,
      busy: false,
      secret:'',
      buttons: [ ]
    };
  },
  created: function() {
  },
  mounted: function() {
    console.log('certificatiiii!!!');
    this.buttons=[
      {label:'Annulla', name:'annulla', neutral:true },
      {label:'Procedere', name:'procedere', brand:true },
    ]
  },
  methods: {
    doCert:function(event) {
      if (event=='close') this.clearCert();
      if (event=='annulla') this.clearCert();
      if (event=='procedere') this.saveCert();

    },
    doqrcode:function(secret) {
        var url='otpauth://totp/AlmaDoc?secret='+secret;
        const qrCode = new QRCodeStyling({
          width: 300,
          height: 300,
          type: "svg",
          data: url,
          image: "/images/icons/ALMA_icona_app.png",
          dotsOptions: {
            color: "#283c50",
            type: "rounded"
          },
          backgroundOptions: {
            color: "#ffffff",
          },
          imageOptions: {
            crossOrigin: "anonymous",
            margin: 20
          }
        });

        qrCode.append(document.getElementById("canvas"));
    },
    clearCert:function() {
      this.$router.push( { path: '/certificati' } );
    },
    saveCert:function() {
      if(confirm('Procedere')) {
        console.log('ok');
        this.creaCert().then((cert)=>{
          apiclient.setcertificate(this.$store.getters.connection, this.$store.getters.connection.username, cert.priv, cert.pub).then( (data) => {
            this.secret=data.data;
            this.$nextTick(() => {
              this.doqrcode(this.secret);
              this.buttons=[ {label:'Chiudi', name:'annulla', brand:true } ];
            })
          })
        })
      }
    },
    creaCert:function() {
      return new Promise((resolve) => {
        var pass='super long and hard to guess secret';
        generateKey({
            type: 'rsa', // Type of the key
            rsaBits: 4096, // RSA key size (defaults to 4096 bits)
            userIDs: [{ name: this.$store.getters.connection.userdata.username }], // you can pass multiple user IDs
            passphrase: pass // protects the private key
        }).then(function(data) {
            console.log('data.privateKey');
            console.log(data.privateKey);
            console.log('data.publicKey');
            console.log(data.publicKey);
            resolve({priv:data.privateKey, pub:data.publicKey});
        })
      })
    }
  }
}
</script>
<style scoped>
</style>

