<template lang="html">

  <div class="pippo slds-scrollable">
    <table class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_col-bordered slds-table_striped">

      <thead>
        <tr class="slds-line-height_reset">
          <th class="bloccato" v-for="(col, index) in cols" v-bind:key="col.order" style="vertical-align:top"  v-on:click="$emit('filter',index)">
            <span class="pippo"  v-bind:style=" (wrapheader?'white-space: normal':'') " >{{col.label}}</span>

            <svg v-if="col.filtered" class="slds-icon slds-icon-text-default slds-icon_x-small" aria-hidden="true">
              <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#filterList"></use>
            </svg>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-on:click="$emit('row',rindex)" v-for="(record, rindex) in records" v-bind:key="record.id" class="slds-hint-parent" style="padding-right:4px;">

          <td v-for="(col,index) in cols" v-bind:key="col.order" v-bind:style="'text-align:'+align(col.type)" class="slds-truncate" >

            <a v-if="index==0" v-bind:href="$route.path+'/'+record['Id']" v-on:click.prevent="select(rindex)">
              <div class="slds-truncate" v-bind:style=" (wrapcell ?'white-space: normal':'') " >
                {{record[col.name] | filter(col.type)}}
              </div>
            </a>

            <span v-if="index>0" class="slds-truncate">

              <span v-if="col.type=='alert' && record[col.name]" class="slds-icon_container slds-icon-utility-announcement" v-bind:title="'Alert'">
                <svg class="slds-icon slds-icon-text-default slds-icon_x-small" aria-hidden="true">
                  <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#alert"></use>
                </svg>
                <span class="slds-assistive-text">Alert</span>
              </span>
              <span v-bind:style="{ 'font-weight': (record.isbold?'bold':''), 'text-decoration': (record.isbold?'underline':'auto') }">
                {{getFieldValue(record,col.name) | filter(col.type) }}
              </span>
              
            </span>

          </td>

        </tr>
      </tbody>
    </table>
  </div>

</template>

<script>
/*
  cols
    label
    order
    name
    type
  records
    name
*/

import numeral from 'numeral'
import moment from 'moment'

export default {
  name: 'gnetTable',
  components: {},
  props: {
    wrapheader: Boolean,
    wrapcell: Boolean,
    cols: Array,
    records: Array,
  },
  data: function () {
      return {
    }
  },
  watch: {
  },
  computed: {},
  mounted: function() {
  },
  filters: {
    filter: function(v, type) {
      switch(type) {
        case 'date':
          if (v) {
            var n=new moment(v);
            return n.format('DD/MM/YYYY');
          }
          return v;
        case 'datetime':
          if (v) {
            var n=new moment(v);
            return n.format('DD/MM/YYYY');
          }
          return v;
        case 'decimal':
          if (v)
            return numeral(v).format('0,0');
          return v;
        case 'currency':
          if (v)
            return numeral(v).format('$ 0,0.00');
          return v;
        case 'alert':
            if (!v)
              return '';
            return '';
        case 'truncate':
          if (v)
            return v.substr(0,32);
      }
      return v;
    }
  },
  methods: {
    getFieldValue: function(obj, path) {
      // eslint-disable-next-line no-redeclare
      for (var i=0, path=path.split('.'), len=path.length; i<len; i++){
        if (obj)
          obj = obj[path[i]];
      }
      return obj;
    },
    align: function(type) {
      switch(type) {
        case 'currency':
        case 'decimal':
          return 'right';
      }
      return 'left';
    },
    select: function(index) {
      console.log('record select');
      this.$emit('click', this.records[index]);
    },
  }
}
</script>

<style scoped>
.bloccato {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
