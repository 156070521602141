<template>
  <div class="home">

    <gnetRecordHeader v-bind:object="object.icon" v-bind:title="$t(object.label)" 
        v-bind:actions="actualView>=0?object.views[actualView].actions:[]" v-on:click="actionClick($event)"
        v-on:change="viewChange($event)"
        v-bind:views="object.views"
        v-bind:actualView="actualView"
    />

    <gnetTable v-bind:cols="cols" v-bind:records="records"
      v-on:click="gotoRecord($event)"
      v-on:filter="filter($event)"
      v-bind:fullheight="true" />      

    <gnetModal v-if="filterPanel.show" v-bind:title="$t('Applica filtro')" v-bind:object="'account'"
      v-on:cancel="filterPanel.show=false;" v-on:submit="doFilter">
      <label class="slds-form-element__label" v-bind:for="'form-element-1'">{{$t(filterPanel.name)}}</label>
      <div class="slds-form-element__control">
        <input v-model="filterPanel.value" type="text" id="form-element-2" v-bind:placeholder="$t('filtro...')" class="slds-input" />
      </div>
    </gnetModal>

    <gnetSpinner v-bind:busy="busy"></gnetSpinner>

    <gnetCreate v-if="showCreate" v-bind:sections="sections" v-bind:durty="durty" v-on:cancel="closecreate" v-on:submit="doCreate" v-on:setfield="setField" v-on:togglesection="toggleSection"></gnetCreate>
  
  </div>
</template>

<script>
// @ is an alias to /src
// import numeral from 'numeral'
// import apiclient from '@/apiclient.js'
import gnetMixLib from '@/mixin.js'
import gnetModal from '../components/modal.vue'
import gnetSpinner from '../components/spinner.vue'

import gnetTable from '../components/table.vue'
import gnetRecordHeader from '../components/recordheader.vue'
import apiclient from '@/apiclient.js'
import mustache from 'mustache'
import gnetCreate from '../components/create.vue'

export default {
  name: 'ztable',
  mixins: [gnetMixLib],
  components: {
    gnetTable,
    gnetModal,
    gnetSpinner,
    gnetRecordHeader,
    gnetCreate
  },
  props: {
    objectName : String,
    docreate: Boolean,
    idparent: String,
    colparent: String,
    emitclick: String,
  },
  data: function() {
    return {
        navparms: {},
        object:{},
        showheader: true,
        cols: [],
        records: [],
        fieldsstring: '',
        filterPanel: {
          show: false,
          value:'',
          name:'',
          index:0,
          filterCol: {}
        },
        sections: [],
        durty: false,
        busy: false,
        headlineActions: [ ],
        actualView: -1,
        showCreate: false,
    };
  },
  created: function() {},
  watch: {
    objectName: function() {
      this.tabChanged();
    },
    idparent: function() {
      this.fetchData();
    }
  },
  mounted: function() {
    this.fetchData();
  },
  computed: {},
  methods: {
    viewChange: function(view) {
      this.actualView=view;
      this.fetchData();
    },
    tabChanged: function() {
      this.actualView=-1;
      this.fetchData();
    },
    fetchData: function() {
      var self=this;
      // this.navparms=this.$store.state.active_tab.params;
      // this.objectName=this.navparms.object;
      this.busy=true;
      if (!this.objectName) {
        alert('objectName null');
        // this.$router.push( { path: '/' } );      
        return;
      }
      this.$store.dispatch('getObject', this.objectName).then(function(obj) {
        self.busy=false;
        console.log('getObject');
        console.log(self.objectName);
        console.log(obj);
        self.object=obj;
        if (self.actualView<0)
          self.actualView=self.object.dview;
        self.computeCols(self.actualView);
        self.setupRecordFields();
        self.getRecords();

        if (self.docreate) {
          console.log('create!');
          self.actionClick('create');
        }

      })
    },
    getView: function(view) {
      if (!view) return this.object.views[this.object.dview];
      return this.object.views[view];
    },
    computeCols:function(view) {
      this.cols.splice(0,this.cols.length);
      var v=this.getView(view);
      v.cols.forEach(function(c) {
        this.cols.push( {name:c.name} );
      }, this )
      this.resolveField(this.object, this.cols);
    },
    doFilter: function() {
      var col=this.cols[this.filterPanel.index];
      col.filtered=this.filterPanel.value;
      this.filterPanel.show=false;
      this.getRecords();
    },
    filter: function(index) {
      console.log('filter');
      var col=this.cols[index];
      if (col.filtered) {
        col.filtered=false;
        col.filterValue='';
        this.getRecords();
      } else {
        this.filterPanel.index=index;
        this.filterPanel.name=col.label;
        this.filterPanel.filterCol=col;
        this.filterPanel.value='';
        this.filterPanel.show=true;
      }
    },
    getRecords: function() {
      console.log('------------->>>>>> getRecords')
      var self=this;
      var filtri=[];
      this.cols.forEach(function(d){
        if (d.filtered) filtri.push(d.name+' like \''+d.filtered+'%\'');
      });
      
      var v=this.getView(this.actualView);

      var soql='select '+this.fieldsstring+' from '+this.objectName;

      var soqlfilters=[];

      if (filtri.length>0) soqlfilters = soqlfilters.concat(filtri);
      
      var mustache_env={
        userlogin: this.$store.state.user.email,
        userfullname: this.$store.state.user.name,
      };

      if (v.filters.length>0) {
        v.filters.forEach(function(f) {
          var name=Object.keys(f)[0]
          var thefilter=f[name];

          var output = mustache.render(thefilter, mustache_env);
          soqlfilters.push(output);
        })
      }

      if (this.idparent && this.colparent) {
        console.log('set id parent '+this.idparent+' on col '+this.colparent);
        var parent=this.colparent+"='"+this.idparent+"'";
        soqlfilters.push(parent);
      }

      if (soqlfilters.length>0)
        soql=soql+' where '+soqlfilters.join(' and ')+' order by createddate desc';
      else
        soql=soql+' order by createddate desc';

      // soql+=' order by Name asc limit 200';

      console.log('table query');
      console.log(soql);
      this.busy=true;
      apiclient.query(this.$store.getters.connection, soql).then(function(data) {
        console.log('records:'+data.length);
        self.records=data;

        var numDocValidati = 0
        for(var r of self.records) {
          if(r.Stato__c != 'Richiesto' && r.Stato__c != 'In attesa di validazione') numDocValidati ++
        }
        if(numDocValidati == data.length) self.$emit('numDocValidati')

        self.busy=false;
      }).catch(function(err){
        console.log(err);
      })
    },
    gotoRecord: function(record) {
      if (this.emitclick) {
        this.$emit(this.emitclick, record);
        return;
      }
      console.log(record);
      var t=this.$store.state.active_tab
      this.$router.push( { path: t.path+'/'+record['Id'] } );      
    },
    setupRecordFields: function() {
      var mfields=new Map();
      mfields.set('Id', null);
      this.mapFields(mfields, this.cols);
      this.fieldsstring=this.uniqueFields(mfields).join(',');
    },
    resolveActionForm: function(forms,action) {
      var iform=-1;
      forms.forEach(function(f,i){
        if (f.name==action) iform=i;
      });
      return iform;
    },
    actionClick: function(action) {
      console.log('action called:'+action);
      switch (action) {
        case 'create':
          var iform=this.resolveActionForm(this.object.forms, action);
          if (iform>=0) {
            this.showCreate=true;
            this.durty=false;
            var setdefault = JSON.parse(JSON.stringify(this.object.forms[iform].sections));
            var self = this;
            setdefault[0].fields.forEach(function(d){
              if(d.name == 'Subject'  && self.$store.state.tasktype != ''){
                d.value = self.$store.state.tasktype;
                self.$set(d, 'value', self.$store.state.tasktype);
                self.$set(d, 'disabled', true);
              }
            })
            this.sections=setdefault;
            this.sections.forEach(function(s) {
              this.resolveField(this.object, s.fields);
            }, this)
          } else {
            alert('action form not found:'+action)
          }
          break;
        default:
          this.$emit(action);
          // alert('action not found:'+action);
        break;
      }
    },
    doCreate: function() {
      var obj={};
      this.sections.forEach(function(s) {
        s.fields.forEach(function(f) {
          if (f.value) obj[f.name]=f.value;
        });
      });
      obj['User__c']= this.$store.state.connection.userdata.Id;
      console.log(obj);
      this.busy=true;
      this.$store.commit('SET_TASKTYPE','');
      var self=this;
      apiclient.create(this.$store.getters.connection, this.object.table, obj).then(function(data) {
        self.showCreate=false;
        self.busy=false;
        console.log(data);
        // self.getRecords();
        self.gotoRecord({Id:data.data.id});
      }).catch(function(err){
        self.showCreate=false;
        self.busy=false;
        alert(err);
      })
    },
    setField: function(e) {
      var s=this.sections[e.section];
      var f=s.fields[e.field];
      var v=e.value;
      // var n=f.name;

      if (f.type=='table') {
        var jv=this.deserialTable( f, f.value );
        var vnew={};
        if (v.section<0) {
          jv.push( vnew );
        } else {
          vnew=jv[v.section];
        }
        vnew[v.field]=v.value;
        f.value=this.serialTable(f,jv);
      } else {
        this.$set(f,'value',v);
      }
      this.durty=true;
    },    
    toggleSection: function(index) {
      this.sections[index].opened=!this.sections[index].opened;
    },
    closecreate: function(){
      this.showCreate = false;
      this.$store.commit('SET_TASKTYPE','');
    }
  }
}
</script>
<style scoped>
</style>

