import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Table from '../views/Table.vue'
import Form from '../views/Form.vue'
import Document from '../views/Document.vue'
import Certificato from '../views/Certificato.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/',                name: 'Home',         component: Home },
  { path: '/:object',         name: 'Object',       component: Table },
  { path: '/certificato/c',   name: 'Certificato',  component: Certificato },
  { path: '/:object/c',       name: 'Object',       component: Table, props: { docreate: true } },
  { path: '/document/:id',    name: 'Document',     component: Document },
  { path: '/:object/:id',     name: 'Record',       component: Form },
]

const router = new VueRouter({
  routes
})

export default router
