<template>
  <div class="home">

    <gnetHeader v-if="showheader" />

    <gnetTabBar
      v-bind:titoloapp="$store.getters.appname"
      v-bind:tabs="$store.state.tabs"
      v-bind:active_tab="$store.state.active_tab.name"
      v-on:click="changeTab($event)" />

    <gnetLayout v-bind:shape="'top'" v-bind:top="true">

      <template v-slot:top>
        <zTable v-if="objectName" v-bind:objectName="objectName" v-bind:docreate="docreate" v-on:certificato="doCert"></zTable>
      </template>

    </gnetLayout>

    <gnetSpinner v-bind:busy="busy"></gnetSpinner>
  
  </div>
</template>

<script>
// @ is an alias to /src
// import numeral from 'numeral'
// import apiclient from '@/apiclient.js'
import gnetMixLib from '@/mixin.js'

import gnetHeader from '../components/header.vue'
import gnetLayout from '../components/layout.vue'
import gnetTabBar from '../components/tabbar.vue'
import gnetSpinner from '../components/spinner.vue'

import zTable from '../components/ztable.vue'

export default {
  name: 'Table',
  mixins: [gnetMixLib],
  components: {
    gnetHeader,
    gnetTabBar,
    gnetSpinner,
    gnetLayout,
    zTable,
  },
  props: {
    objectxxx : String,
    docreate: Boolean
  },
  watch: {
    '$route': 'tabChanged'
  },
  data: function() {
    return {
      objectName: '',
      showheader: true,
      certShow:false,
      busy: false,
    };
  },
  created: function() {
  },
  mounted: function() {
    this.tabChanged();
  },
  methods: {
    doCert:function() {
      this.$router.push( { path: '/certificato/c' } );
    },
    tabChanged: function() {
      var navparms=this.$store.state.active_tab.params;
      this.objectName=navparms.object;
    },
  }
}
</script>
<style scoped>
</style>

