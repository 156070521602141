import Vue from 'vue'
import Vuex from 'vuex'
import metaremote from '@/metaremote.js'
import jwt from 'jsonwebtoken'
import apiclient from '@/apiclient.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    app: 'AlmaDoc 0.9',
    logo: 'app.png',

    active_tab:'',
    tabs:[],
    active_app:'',
    apps:[],
    tasktype:'',
    objects: {},

    login: false,
    ready: false,
    auth: {},
    connection: {
      myid: '',
      myid_token: '',
      mytokenizedid: '',
      mytoken: '',
      userdata: {},
      zona: '',
    },
    // google payload
    googleIdentity: {},
    // google decoded user
    user: {
      id: '',
      id_token: '',
      name: '',
      imageUrl: '',
      email: ''
    },
    multizona: false,
    position: {
      gps: false,
      lat: undefined,
      lng: undefined
    },
    devices: [],
    deviceback:'',
    map: {},
    cert:''
  },
  getters: {
    appname: function(state) { return state.app },
    tasktype: function(state) { return state.tasktype },
    connection: function(state) { return state.connection },
    logged: function(state) { return state.login && state.ready},
    auth: function(state) { return state.auth },
    googleIdentity: function(state) { return state.googleIdentity },
    map: function(state) { return state.map },
    position: function(state) { return state.position },
    zona: function(state) { return state.connection.zona },
  },
  mutations: {
    SET_CERT: function(state, payload)        { state.cert = payload; },
    SET_ZONA: function(state, payload)        { state.zona = payload; state.connection.zona = payload; },

    SET_TABS: function(state, payload) { state.tabs=payload },
    SET_ACTIVE_TAB: function(state, payload) { state.active_tab=payload },
    SET_TASKTYPE: function(state, payload) { state.tasktype=payload },

    SET_LOGO: function(state, payload) { state.logo=payload },
    SET_APPS: function(state, payload) { state.apps=payload },
    SET_ACTIVE_APP: function(state, payload) {
      state.apps.findIndex(function(a,i) {
        if (a.name==payload) {
          state.app=a.title;
          state.active_app=i;
        }
      }) 
    },

    SET_AUTH: function(state, payload)              { state.auth = payload; },

    SET_CONNECTION: function(state, payload)        {
      state.connection = payload;
      if (payload.userdata && payload.userdata.agentcodes && payload.userdata.agentcodes.length>0) {
        state.connection.zona = payload.userdata.agentcodes[0];
        if (payload.userdata.agentcodes.length>1) state.multizona=true;
      }
    },
    SET_CONNECTION_TOKEN: function(state, payload)  {
      state.connection.mytoken = payload.mytoken;
      state.connection.mytokenizedid = payload.mytokenizedid;
      state.connection.userdata = payload.userdata;

      if (payload.userdata && payload.userdata.agentcodes && payload.userdata.agentcodes.length>0) {
        state.connection.zona = payload.userdata.agentcodes[0];
        if (payload.userdata.agentcodes.length>1) state.multizona=true;
      }
    },
    SET_USERDATA: function(state, payload)  {
      state.connection.userdata = payload.userdata;
    },
    SET_LOGIN: function(state, payload)             { state.login = payload; },
    SET_READY: function(state, payload)             { state.ready = payload; },
    SET_GOOGLE_IDENTITY: function(state, payload) {
      state.googleIdentity = payload;
      var profile = payload.getBasicProfile();
      state.user.id_token=payload.getAuthResponse().id_token;
      state.user.id = profile.getId();
      state.user.name = profile.getName();
      state.user.imageUrl = profile.getImageUrl();
      state.user.email = profile.getEmail();
      //this.user.givename=this.profile.getGivenName();
      //this.user.familyname=this.profile.getFamilyName();
    },
    SET_MAP: function(state, payload)               { state.map = payload; },
    SET_POSITION: function(state, payload) {
      state.position.gps = true;
      state.position.lat = payload.lat;
      state.position.lng = payload.lng;
    },
    SET_DEVICES: function(state, payload) { state.devices=payload; },
    SET_DEVICE_BACK: function(state, payload) { state.deviceback=payload; },
    SET_OBJECT: function(state,payload) { 
      var o={}
      Object.assign(o,payload); // shallow copy
      o.expire=Date.now()+60000;
      o.mfields={};
      o.fields.forEach(function(f,i) {
        o.mfields[f.name]=i;
      });
      o.mviews={};
      o.views.forEach(function(v,i) {
        o.mviews[v.name]=i;
        if (v.default) o.dview=i;
      });
      o.mforms={};
      o.forms.forEach(function(v,i) {
        o.mforms[v.name]=i;
        if (v.default) o.dform=i;
      });
      state.objects[o.name.toLowerCase()]=o; 
    },
    GET_USER_DATA:function(state) {
      console.log('GET_USER_DATA');
      var userData=localStorage.getItem('almadoc');
      console.log(userData);
      if (userData) {
        var ud=JSON.parse(userData);
        state.connection.username=ud.username;
        state.connection.userdata=ud.token;
        state.connection.status=ud.status;
        state.login=true;

        if (window.OpenReplay && window.OpenReplay.setUserID) {
          console.log('setuserid');
          window.OpenReplay.setUserID(ud.username);
        }

        console.log('localStorage',localStorage)
        var jwtpayload = localStorage.getItem('userprofile');
        if (jwtpayload) {
          jwt.verify(jwtpayload, apiclient.secret, function(err, data) {
            if (err) {
              console.log('bad request: jwt broken');
              return;
            } else {
              state.userprofile=data;
              console.log('state.userprofile', data)
            }
          });
        }
      }
    },
    RESET_USER_DATA:function(state) {
      console.log('RESET_USER_DATA');
      localStorage.removeItem('userprofile');
      localStorage.removeItem('almadoc');
      // state.connection.username='';
      // state.connection.userdata='';
      // state.connection.password='';
      // state.connection.token='';
      state.connection = {}
      state.user={};
      state.login = false
    },
  },
  modules: {
  },
  actions: {
    getLocalUser: function(context) {
      return new Promise(function(resolve, reject) {
        var userData=localStorage.getItem('almadoc');
        apiclient.getKey();
        console.log(userData);
        console.log(apiclient.secret);
        if (userData) {
          jwt.verify(userData, apiclient.secret, function(err, data) {
            if (err) {
              console.log('bad localStorage: jwt broken');
              reject();
            } else {
              context.commit('SET_USERDATA', data);
              resolve(data);
            }
          });
        } else {
          reject()
        }
    })
  },
    setLocalUser: function(context, payload) {
      console.log('setLocalUser');
      console.log(payload);
      var jwtpayload = jwt.sign( payload, apiclient.secret);
      localStorage.setItem('almadoc', jwtpayload); 
    },
    getDevices: function(context) {
      return new Promise(function(resolve, reject) {
        if (!navigator.mediaDevices) reject();
        navigator.mediaDevices.enumerateDevices().then(function(devices) {
          context.commit('SET_DEVICES', devices);
          var deviceId;
          devices.forEach(function(d) {
            if (d.kind == "videoinput" && d.label.toLowerCase().includes("back")) {
              context.commit('SET_DEVICE_BACK', deviceId);
            }
          });
          resolve();
        }).catch(function(err) {
          reject(err);
        })
      });
    },
    getPosition: function(context) {
      return new Promise(function(resolve, reject) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function(position) {
            var pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };

            console.log('position ok');
            console.log(pos);
            context.commit('SET_POSITION', pos);
            resolve();
          }, function() {
            reject();
          });
        } else {
          reject();
        }
      });
    },
    showMapMsg: function(context, payload) {
      var pos = context.getters.position;
      var map = context.getters.map;
      console.log(pos);
      // eslint-disable-next-line no-undef
      this.infoWindow = new google.maps.InfoWindow;
      this.infoWindow.setPosition(payload.pos);
      this.infoWindow.setContent(payload.msg);
      this.infoWindow.open(map);
    },
    moveToMyPos: function(context) {
      var pos = context.getters.position;
      var map = context.getters.map;
      console.log(pos);
      map.setCenter(pos);
    },
    signOut: function(context) {
      // eslint-disable-next-line no-undef
      // var auth2 = gapi.auth2.getAuthInstance();
      // auth2.signOut().then(function() {
      //   console.log('User signed out.');
      //   context.commit('SET_LOGIN', false);
      // });
      context.commit('RESET_USER_DATA');
    },
    initmeta: function(context) {
      return new Promise(function(resolve) {
        console.log('initmeta');
        context.dispatch('gettabs').then(function() {
          context.dispatch('getapps').then(function() {
            resolve();
          });
        })
      })
    },
    getObject: function(context, object) {
      return new Promise(function(resolve) {
        var obj=object; // .toLowerCase();
        console.log('object:'+obj);
        if (context.state.objects[obj] && context.state.objects[obj].expire>Date.now()) { 
          console.log('object cache');
          resolve(context.state.objects[obj]); 
          return; 
        }
        metaremote.get(context.state.connection, 'object-'+obj).then(function(res) {
          console.log('object not in cache:'+obj);
          console.log(res);
          context.commit('SET_OBJECT', res);
          console.log(context.state.objects[obj] );
          resolve(context.state.objects[obj] );
        });  
      });
    },
    getapps: function(context) {
      return new Promise(function(resolve) {
        metaremote.get(context.state.connection, 'apps').then(function(apps) {
          console.log('apps');
          console.log(apps);
          context.commit('SET_APPS', apps );
          context.commit('SET_ACTIVE_APP', apps[0].name);    
          context.commit('SET_LOGO', apps[0].logo);    
          resolve();
        });
      });
    },
    gettabs: function(context) {
      return new Promise(function(resolve) {
        metaremote.get(context.state.connection, 'tabs').then(function(tabs) {
          context.commit('SET_TABS', tabs );
//          context.commit('SET_ACTIVE_TAB', tabs[0].name);    
          resolve();
        });
      });
    },
  }
})
