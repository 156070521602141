import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from './locales/en.js'
import it from './locales/it.js'

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  messages: {
    en: en,
    it: it,
  }
})
