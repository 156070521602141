/* eslint-disable  */

<template lang="html">

  <div class="slds-context-bar">
    <div class="slds-context-bar__primary">
      <div class="slds-context-bar__item slds-context-bar__dropdown-trigger slds-dropdown-trigger slds-dropdown-trigger_click slds-no-hover">

        <div v-if="true" class="slds-context-bar__icon-action">
          <button class="slds-button slds-icon-waffle_container slds-context-bar__button" title="Description of the icon when needed">
            <span class="slds-icon-waffle">
              <span class="slds-r1"></span>
              <span class="slds-r2"></span>
              <span class="slds-r3"></span>
              <span class="slds-r4"></span>
              <span class="slds-r5"></span>
              <span class="slds-r6"></span>
              <span class="slds-r7"></span>
              <span class="slds-r8"></span>
              <span class="slds-r9"></span>
            </span>
            <span class="slds-assistive-text">Open App Launcher</span>
          </button>
        </div>

        <span class="slds-context-bar__label-action slds-context-bar__app-name">
          <span class="slds-truncate" title="App Name">{{titoloapp}}</span>
        </span>
      </div>
    </div>
    <nav class="slds-context-bar__secondary" role="navigation">
      <ul class="slds-grid">

        <li v-for="(tab, index) in tabs" v-bind:key="tab.order" class="slds-context-bar__item" 
        v-bind:class="{ 'slds-is-active': tab.path==getPath($route.path) }">
          <a v-on:click.prevent="clickTab(index)" href="javascript:void(0);" class="slds-context-bar__label-action" v-bind:title="tab.name">
            <span v-if="active_tab==tab.name" class="slds-assistive-text">Current Page:</span>
            <span class="slds-truncate" v-bind:title="tab.name">{{ $t(tab.name) }}</span>
          </a>
        </li>

      </ul>
    </nav>
  </div>

</template>

<script>

export default {
  name: 'gnetTabBar',
  components: {
  },
  props: {
    titoloapp: String,
    tabs: Array,
    active_tab: String

  },
  data() {
    return {
        daticliente: false
    }
  },
  computed: {
  },
  async created() {

  },
  methods: {
    getPath: function(p) {
      var i=p.indexOf('/',1);
      if (i>=0)
        return p.substr(0,i);
      return p;
    },
    clickTab: function(index) {
      var t=this.tabs[index];
      this.$emit('click', t );     
    },
  }
}
</script>

<style scoped>

</style>
