<template>
  <div id="app">
    <router-view v-if="$store.getters.logged"/>

    <div v-if="!$store.getters.logged">
      <loginplain v-on:submit="doLogin($event)"></loginplain>
    </div>

    <div v-if="loginerror">

      <div class="demo-only" style="height:4rem">
        <div class="slds-notify_container slds-is-relative">
          <div class="slds-notify slds-notify_toast slds-theme_error" role="status">
            <span class="slds-assistive-text">error</span>
            <span class="slds-icon_container slds-icon-utility-error slds-m-right_small slds-no-flex slds-align-top" title="Description of icon when needed">
              <svg class="slds-icon slds-icon_small" aria-hidden="true">
                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#error"></use>
              </svg>
            </span>
            <div class="slds-notify__content">
              <h2 class="slds-text-heading_small">Your user is not enabled for this application.</h2>
              <p>Your user is not enabled for this application: contact support to verify your permissions.</p>
            </div>
            <div class="slds-notify__close">
              <button class="slds-button slds-button_icon slds-button_icon-inverse" title="Close" @click.prevent="loginerror = false">
                <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
                  <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                </svg>
                <span class="slds-assistive-text">Close</span>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import loginplain from '@/components/loginplain.vue'
import apiclient from '@/apiclient.js'
import numeral from 'numeral'
import metaremote from '@/metaremote.js'
//import moment from 'moment'

export default {
  name: 'app',
  components: {
    loginplain
  },
  data: function() {
    return {
      loginerror:false
    }
  },
   mounted: function() {

    if (numeral.locales['it'] === undefined) {
      numeral.register('locale', 'it', {
          delimiters: { thousands: '.', decimal: ',' },
          abbreviations: { thousand: 'k', million: 'm', billion: 'b', trillion: 't' },
          currency: { symbol: '€'}
      });

      numeral.locale('it');
    }

    this.$store.commit('GET_USER_DATA')

  },
  methods: {
    setPath: function() {
      console.log( 'setPath!!!!' );
      if (this.$route.path && !this.$store.state.active_tab) {
        console.log('aAAAaaaarrgh');
        if (this.$store.state.tabs) {
          console.log(this.$route.path);
          var p=this.$route.path.split('/');
          var p2='/'+p[1];
          this.$store.state.tabs.forEach(function(t) {
            if (t.path==p2) {
              console.log('SET_ACTIVE_TAB');
              this.$store.commit('SET_ACTIVE_TAB', t);
            }
          }, this);
        } else {
          console.log('NooOooOoOOoOOOo!!!');
        }
      }

      console.log( 'this.$store.state.active_tab' );
      console.log( this.$store.state.active_tab );
    },
    doLogin: function(event) {
      console.log('present myself:'+event.username);
      
      var connection={
        username: event.username,
        password: event.password,
        commit: this.$store.commit,
        userdata: {},
        zona: '',
        key:'',
      };

      console.log('app.doLogin.connection', connection)
      var self=this;
      apiclient.getKey('priv.pem').then(function(key) {
        connection.key=key.data;
        metaremote.setSecret(apiclient.secret);        
        apiclient.login(connection)
        .then(function(resp) {
          self.$store.commit('SET_CONNECTION', connection );
          self.$store.commit('SET_CONNECTION_TOKEN', resp );
          self.$store.commit('SET_LOGIN', true);
          self.$store.dispatch('setLocalUser',self.$store.getters.connection.userdata);
          self.$store.dispatch('initmeta').then(function() {
            self.setPath();
            self.$store.commit('SET_READY', true);
          })      

          // eslint-disable-next-line no-undef
          // gtag('set', {'user_id': JSON.stringify(connection.userdata.agentcodes)});
        }).catch(function(err){
          console.log('errore di autenticazione');
          console.log(err);
          self.loginerror=true;
        })        
      }).catch(function(err){
        console.log(err);
      })      
    }
  }
}
</script>

<style>
</style>
