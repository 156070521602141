<template lang="html">
    <header >
      <a href="javascript:void(0);" class="slds-assistive-text slds-assistive-text_focus">Skip to Navigation</a>
      <a href="javascript:void(0);" class="slds-assistive-text slds-assistive-text_focus">Skip to Main Content</a>
      <div class="slds-global-header slds-grid slds-grid_align-spread" style="background-color: #283c50;">

        <div class="slds-global-header__item">
          <div class="mylogo" v-bind:style="bgimage"></div>
        </div>

        <div class="slds-global-header__item slds-global-header__item_search">

          <formfield
            v-if="false && $store.state.multizona"
            v-bind:title="sceltaZona.title"
            v-bind:type="sceltaZona.type"
            v-bind:name="sceltaZona.name"
            v-bind:value="$store.getters.zona"
            v-bind:placeholder="sceltaZona.placeholder"
            v-bind:tabindex="sceltaZona.tabindex"
            v-bind:disabled="sceltaZona.disabled"
            v-bind:options="zone"
            v-on:change="setField($event)"
          />


            <gnetLookup 
              v-if="false"
              v-bind:label="'Cerca'"
              v-bind:object="'Account'"
              v-bind:founded="founded"
              v-bind:selected="found"
              v-on:change="search"
              v-on:click="selected($event)"
            ></gnetLookup>
        </div>

        <div class="slds-global-header__item">
          <ul class="slds-global-actions">

            <li v-if="actionAdd" class="slds-global-actions__item">
              <div class="slds-dropdown-trigger slds-dropdown-trigger_click slds-is-open">
                <button v-on:click="$emit('add')" class="slds-button slds-button_icon slds-button_icon slds-button_icon-container slds-button_icon-small slds-global-actions__task slds-global-actions__item-action" aria-haspopup="true" title="Global Actions">
                <svg class="slds-button__icon" aria-hidden="true">
                  <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#add"></use>
                </svg>
                <span class="slds-assistive-text">Global Actions</span>
              </button>
              </div>
            </li>

            <gnetHeaderNotification v-bind:notifications="notifications" v-on:readed="readed($event)"></gnetHeaderNotification>

            <li class="slds-global-actions__item">
              <div class="slds-dropdown-trigger slds-dropdown-trigger_click">
                <button v-on:click="avatar" class="slds-button slds-global-actions__avatar slds-global-actions__item-action" title="person name" aria-haspopup="true">
                  <span class="slds-avatar slds-avatar_circle slds-avatar_medium">
                    <img v-if="!$store.state.user.imageUrl" alt="Person name" src="/assets/images/avatar2.jpg" title="User avatar" />
                    <img v-if="$store.state.user.imageUrl" alt="Person name" v-bind:src="$store.state.user.imageUrl" title="User avatar" />
                  </span>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </header>
</template>

<script>
import gnetLookup from './lookup.vue'
import gnetHeaderNotification from './headernotification.vue'
import formfield from './formfield.vue'

export default {
  name: 'gnetHeader',
  components: {
    gnetHeaderNotification,
    gnetLookup,
    formfield
  },
  props: {
    // zone: Array,
    actionAdd: Boolean,
  },
  data() {
    return {
      zone:[],
      sceltaZona: { id:1, title:'Zona', value: this.$store.getters.zona, name:'Zona', type:'picklist', placeholder:'Selezionare un codice zona', tabindex:1, disabled: false },

      founded: [],
      found:undefined,
      notifications:[
        /*
        {
          order: 1,
          title: 'messaggio 1',
          content:'sono il messaggio 1',
          datetime:'',
          who: 'Paolo',
          avatar: 'avatar1.jpg',
          unreaded:true,
        },
        {
          order: 2,
          title: 'messaggio 2',
          content:'sono il messaggio 2',
          datetime:'',
          who: 'Mario',
          avatar: '',
          unreaded:true,
        }
        */
      ],
    }
  },
  mounted: function() {
    this.zone=[];
    window.document.title=this.$store.state.app;
  },
  async created() {

  },
  computed: {
    bgimage: function() {
      return {
        "background-image": "url(/images/logo/"+this.$store.state.logo+")"
      };
    }
  },
  methods: {
    setField: function(value) {
      console.log('setField:');
      console.log(value);
      this.$store.commit('SET_ZONA', value);
    },
    search: function() {
        this.founded=[
          /*
          {
            id:1,
            name:'uno',
            value:'ciao'
          },
          {
            id:2,
            name:'due',
            value:'bau'
          }
          */
        ];
    },
    selected: function(index) {
        console.log('selected:'+index);
        console.log(this.founded[index]);
    },
    avatar: function() {
      this.$store.dispatch('signOut');
    },
    readed: function(index) {
      console.log('readed');
      this.notifications[index].unreaded=false;
    }
  }
}
</script>

<style scoped>
.mylogo {
    width: 12.5rem;
    height: 2.5rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
}
</style>
