import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import gnLogger from './logger.js'

import i18n from './translate.js'

Vue.config.productionTip = true

Vue.use(gnLogger);

var myApp=new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

window.myApp=myApp;

// eslint-disable-next-line no-unused-vars
router.afterEach(( to, from ) => {
  console.log('ga:'+to.path)
  // eslint-disable-next-line no-undef
  gtag('event', 'pageview', {
    'event_category' : to.path,
    'event_label' : from.path
  });
  console.log('ga:'+to.path)
});