<template lang="html">

    <div class="slds-page-header">

      <div class="slds-page-header__row">

        <div class="slds-page-header__col-title">
          <div class="slds-media">
            <div class="slds-media__figure">
              <span class="slds-icon_container slds-icon-standard-account" v-bind:title="object">
                <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
                  <use v-bind:xlink:href="'/assets/icons/standard-sprite/svg/symbols.svg#'+object"></use>
                </svg>
                <span class="slds-assistive-text">{{object}}</span>
              </span>
            </div>
            <div class="slds-media__body">
              <div class="slds-page-header__name">
                <div class="slds-page-header__name-title">
                  <h1>
                    <span class="slds-page-header__title slds-truncate" v-bind:title="title">{{title}}</span>
                    <span v-if="viewExists && actualView>=0" v-on:click="showlist=!showlist" class="slds-page-header__title slds-truncate" v-bind:title="views[actualView].label">{{views[actualView].label}}</span>
                  </h1>
                </div>

                <div v-if="viewExists" v-on:click="showlist=!showlist" class="slds-page-header__name-switcher">
                  <div class="slds-dropdown-trigger slds-dropdown-trigger_click">
                    <button  v-on:blur="closeOnBlur" class="slds-button slds-button_icon slds-button_icon-small" aria-haspopup="true" title="Switch list view">
                      <svg class="slds-button__icon slds-icon_x-small" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#down"></use>
                      </svg>
                      <span class="slds-assistive-text">Switch list view</span>
                    </button>
                  </div>
                </div>

              </div>
              <p v-if="subtitle" class="slds-page-header__name-meta">{{subtitle}}</p>

              <div v-if="showlist" class="slds-combobox_container slds-size_small">
                <div  id="listbox-unique-id" role="listbox" class="slds-dropdown slds-dropdown_fluid slds-dropdown_length-8 ">
                  <ul class="slds-listbox slds-listbox_vertical" role="presentation">
                    <li v-on:click.prevent="showlist=false; $emit('change', index); show='false'" v-for="(option, index) in views" v-bind:key="option.name" role="presentation" class="slds-listbox__item">
                      <div id="listbox-option-unique-id-01" class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_small slds-media_center" role="option">
                        <span class="slds-media__figure">
                          <svg class="slds-icon slds-icon_x-small slds-listbox__icon-selected" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                          </svg>
                        </span>
                        <span class="slds-media__body">
                          <span class="slds-truncate" v-bind:title="option">{{option.label}}</span>
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div  class="slds-page-header__col-actions">
          <div class="slds-page-header__controls">
            <div v-for="(other,index) in others" v-bind:key="other.name" class="slds-page-header__control">
              <img style="width: 100px" v-bind:src="other.data" v-on:click.prevent="$emit('other',index)"/>
            </div>

            <div v-for="action in actions" v-bind:key="action.name" class="slds-page-header__control">

              <button v-on:click.prevent="$emit('click',action.name)" v-bind:class="action.class?action.class:'slds-button_neutral'"
                  class="slds-button slds-button_stateful slds-not-selected pippo" aria-live="assertive">
                <span class="slds-text-not-selected">
                  <svg v-if="action.symbol" class="slds-button__icon slds-button__icon_small slds-button__icon_left" aria-hidden="true">
                    <use v-bind:xlink:href="'/assets/icons/utility-sprite/svg/symbols.svg#'+action.symbol"></use>
                  </svg>
                  {{action.title}}
                </span>
              </button>

            </div>

          </div>
        </div>

      </div>

      <div v-if="fields" class="slds-page-header__row slds-page-header__row_gutters">
        <div class="slds-page-header__col-details">
          <ul class="slds-page-header__detail-row">
            <li v-for="(field) in fields" v-bind:key="field.label" class="slds-page-header__detail-block" style="padding-right: 0px;">
              <div class="slds-text-title slds-truncate" v-bind:title="field.label">{{field.label}}</div>
              <div v-bind:title="field.value">
                <a v-if="field.link" v-bind:href="field.link">{{field.value | filter(field.type) }}</a>
                <span v-if="field.type=='alert' && field.value" class="slds-icon_container slds-icon-utility-announcement" v-bind:title="'Alert'">
                  <svg class="slds-icon slds-icon-text-default slds-icon_x-small" aria-hidden="true">
                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#alert"></use>
                  </svg>
                  <span class="slds-assistive-text">Alert</span>
                </span>
                <span style="font-size: 12px;" v-if="!field.link">{{field.value | filter(field.type) }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>

    </div>
</template>

<script>
import numeral from 'numeral'
import moment from 'moment'

export default {
  name: 'gnetRecordHeader',
  components: {
  },
  props: {
    object: String,
    title: String,
    subtitle: String,

    fields: Array,
    actions: Array,
    others: Array,
    views: Array,
    actualView: Number    
  },
  data() {
    return {
      viewsel: {},
      showlist: false,
    }
  },
  mounted: function() {
    if (this.viewExists)
      this.viewsel = this.views[0];
  },
  filters: {
    filter: function(v, type) {
      switch(type) {
        case 'date':
          if (v) {
            var n=new moment(v);
            return n.format('DD/MM/YYYY');
          }
          return v;
        case 'currency':
          return numeral(v).format('$ 0,0.00');
        case 'alert':
            return v;
      }
      return v;
    }
  },
  computed: {
    viewExists: function() {
      if (this.views && this.views.length>0) return true;
      return false;
    }

  },
  async created() {

  },
  methods: {
    closeOnBlur: function() {
      var self=this;
      setTimeout(function() {
        self.showlist=false;
      },200);
    }
  }
}
</script>

<style lang="" scoped>

</style>
