<template lang="html">
  <div >
    <section 
      v-bind:class="hspaceClass"
      role="dialog" tabindex="-1" aria-labelledby="modal-heading-01" aria-modal="true" aria-describedby="modal-content-id-1" class="slds-modal slds-fade-in-open">
      <div class="slds-modal__container">
        <header class="slds-modal__header">

          <button v-bind:xdisabled="disabled" v-on:click.prevent="$emit('cancel')" class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" title="Chiudi">
            <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
              <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
            </svg>
            <span class="slds-assistive-text">Chiudi</span>
          </button>
          <h2 id="modal-heading-01" class="slds-text-heading_medium slds-hyphenate">{{title}}</h2>

        </header>
        <div class="slds-modal__content slds-p-around_medium" id="modal-content-id-1">
          <slot></slot>
        </div>
        <footer class="slds-modal__footer">

          <button v-if="!wmode" class="slds-button slds-button_neutral" v-on:click.prevent="$emit('cancel')" v-bind:xdisabled="disabled" >Cancel</button>
          <button v-if="!wmode" class="slds-button slds-button_brand" v-on:click.prevent="$emit('submit')" v-bind:disabled="disabled" >Save</button>

          <button v-for="b in buttons" 
            v-bind:key="b.name" class="slds-button" 
            v-bind:class="{'slds-button_neutral':b.neutral,'slds-button_brand':b.brand}" 
            v-on:click.prevent="$emit('click',b.name)" 
            v-bind:disabled="b.disabled" >{{b.label}}
          </button>

        </footer>
      </div>
    </section>
    <div class="slds-backdrop slds-backdrop_open"></div>
  </div>
</template>

<script>

export default {
  name: 'gnetModal',
  components: {
  },
  props: {
    title: String,
    hspace: String,
    disabled: Boolean,
    wmode: Boolean,
    buttons: Array
  },
  data() {
    return {
      fields: []
    }
  },
  computed: {
    hspaceClass: function() {
      if (!this.hspace) return '';
      if (this.hspace=='small') return 'slds-modal_small';
      if (this.hspace=='medium') return 'slds-modal_medium';
      if (this.hspace=='large') return 'slds-modal_large';
      return '';
    }
  },
  async created() {

  },
  methods: {
  }
}
</script>

<style scoped>

</style>
