<template>
  <div class="home">

    <gnetHeader v-if="showheader"></gnetHeader>

    <gnetTabBar
      v-bind:titoloapp="$store.getters.appname"
      v-bind:tabs="$store.state.tabs"
      v-bind:active_tab="$store.state.active_tab.name"
      v-on:click="changeTab($event)"
    >
    </gnetTabBar>

    <picker v-if="false" v-on:click="doaction($event)" v-bind:title="title" v-bind:tasks="tasks" style="padding: 10px"></picker>

  </div>
</template>

<script>
// @ is an alias to /src
// import numeral from 'numeral'
// import apiclient from '@/apiclient.js'
import gnetMixLib from '@/mixin.js'

import gnetHeader from '../components/header.vue'
import gnetTabBar from '../components/tabbar.vue'
import picker from '../components/picker.vue'

export default {
  name: 'Home',
  mixins: [gnetMixLib],
  components: {
    gnetHeader,
    gnetTabBar,
    picker
  },
  data: function() {
    return {
        showheader: true,
        title: "Seleziona un'azione rapida",
        tasks: [
          { label: 'Attività uno', name: 'leadtaskcreate', symbol:'call' },
          { label: 'Attività due', name: 'casetaskcreate', symbol:'call' },
          { label: 'Attività tre', name: 'leadcreate', symbol:'people' },
        ]
    };
  },
  created: function() {
    console.log('registering service worker');
    if ('serviceWorker' in navigator) {
      console.log('service worker available');
      // navigator.serviceWorker.register('/sw.js');
    }
  },
  mounted: function() {
  },
  methods: {
    
    findTab: function(name) {
      var p={};
      this.$store.state.tabs.forEach(function(t) {
        if (t.name==name) {
          p=t;
        }
      }, this);
      return p;
    },
    doaction: function(name) {
      var p;
      switch (name) {
        case 'leadtaskcreate':
          p=this.findTab('Tasks');
          this.$store.commit('SET_ACTIVE_TAB', p);
          this.$store.commit('SET_TASKTYPE','Lead');
          this.$router.push( { path: p.path+'/c' } );
          break;
        case 'casetaskcreate':
          p=this.findTab('Tasks');
          this.$store.commit('SET_ACTIVE_TAB', p);
          this.$store.commit('SET_TASKTYPE', 'Caso');
          this.$router.push( { path: p.path+'/c' } );
          break;
        case 'leadcreate':
          p=this.findTab('Leads');
          this.$store.commit('SET_ACTIVE_TAB', p);
          this.$router.push( { path: p.path+'/c' } );
          break;
      }
    }
  }
}
</script>
<style scoped>
</style>

