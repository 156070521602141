export default {
    // nome app
    "SalesPaddy":"SalesPaddy",
    // tabs
    "Home":"Home",
    "Account":"Customers",
    // filtro nelle tabelle
    "Applica filtro":"Apply filter",
    "filtro...":"filter...",
    // azioni salon
    "Crea":"Create",
    // salons: tabella e form
    "Ragione Sociale":"Company",
    "Insegna":"Signboard",
    "Autocomplete":"Autocomplete",
    "Indirizzo":"Street",
    "Città":"City (full name)",
    "Cap":"Postalcode",
    "Paese":"State (full name)",
    "Provincia":"State (full name)",
    "Nazione":"Country (full name)",
    "Email":"Email",
    "Telefono":"Phone",
    "Web":"Web",
    "Attivo":"Active",
    "Alert":"Alert",
    "Tipo":"Type",
    "Latitude":"Latitude",
    "Longitude":"Longitude",
    "Latitude2":"Latitude2",
    "Longitude2":"Longitude2",
    // sezioni form
    "Dati principali":"Primary Information",
    "Sede Legale":"Address",
    "Contatto":"Contact",
    "Dati qualitativi":"Qualitative Informations",
    // sezioni form
    "Dati Anagrafici":"Anagrafic Informations",
    // mappa
    "Mappa Saloni":"Salons Map",
    "Saloni visualizzati:":"In map Salons:",
    "Leads":"Leads",
    "Saloni":"Salons",
    "Tipo Visualizzazione:":"View type:",
    "Heatmap":"Heatmap",
    "Markers":"Markers",
    "Scheda Lead":"Lead Info",
    "Scheda Cliente":"Salon Info",
    "Google Maps":"Google Maps",
    "Risultato ricerca:":"Search results:",
    "Corrispondenze trovate:":"Matches found:",
    "Valutazione:":"Evaluation:",
    "Recensioni:":"Reviews:",
    "x":"x",

}