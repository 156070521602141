<template>
  <div class="home">

    <gnetHeader v-if="showheader" />

    <gnetTabBar
      v-bind:titoloapp="$store.getters.appname"
      v-bind:tabs="$store.state.tabs"
      v-bind:active_tab="$store.state.active_tab.name"
      v-on:click="changeTab($event)" />

    <gnetLayout v-bind:shape="'11'">

      <template v-slot:left>
        <zRecord v-bind:recordId="recordId" v-bind:objectName="objectName" v-bind:headlineActions="headlineActions" v-on:ido1="ido1()"  v-on:ido2="ido2()" v-on:ready="setOppty($event)"></zRecord>
      </template>

      <template v-slot:right>
        <!--
        <zTimeline v-if="objectName!='task'" v-bind:recordId="recordId" v-bind:objectName="objectName"></zTimeline>
        <playbook v-if="objectName=='task'" v-bind:recordId="recordId"></playbook>
        -->
        <span  v-if="objectName">
          <zTable v-for="rel in related" v-bind:key="rel.name" v-bind:objectName="rel.object" v-bind:colparent="rel.colparent" 
            v-bind:idparent="recordId" v-bind:emitclick="'click'" v-on:richiesta="nuovarichiesta" v-on:click="showdocument" v-on:numDocValidati="SetAllDocValidati">
          </zTable>
        </span>
      </template>

    </gnetLayout>

    <alert v-if="showAlert" hspace="large" v-bind:title="alertTitle" v-on:cancel="clearIdo" :message="alertMessage"></alert>

    <gnetModal v-if="idoShow" hspace="large" v-bind:title="'Valutazione Idoneità'" v-bind:object="'document'" v-on:cancel="clearIdo" v-on:submit="saveIdo" :disabled="idoval.Protocollo__c == '' || idoval.Note_sulle_restrizioni_alla_mansione__c == ''">
          <gnetFormField  key="1" type="text" title="Protocollo Idoneità" name="Protocollo__c" 
              v-bind:value="idoval.Protocollo__c" 
              placeholder="Protocollo..."  v-bind:tabindex=3 v-bind:required="true"
              v-on:change="changeField('Protocollo__c', $event)"
          />

          <gnetFormField  key="8" type="picklist" title="Presenza Disturbi dell'Apprendimenti" name="Disturbi_Presenti" 
              v-bind:options="sino" v-bind:value="idoval.Disturbi_Presenti" 
              placeholder="Indicazioni..."  v-bind:tabindex=1 v-bind:required="true"
              v-on:change="changeField('Disturbi_Presenti', $event)"
          />          

          <span v-if="idoval.Disturbi_Presenti=='Si'">
            <gnetFormField v-for="(disturbo, idisturbo) in disturbi" v-bind:key="idisturbo+50"
                  type="checkbox" v-bind:title="disturbo" v-bind:name="'Disturbo'+(idisturbo+50)" 
                  v-bind:value="idoval.Disturbi[idisturbo]" 
                  placeholder="Note..."  v-bind:tabindex=2+idisturbo v-bind:required="false"
                  v-on:change="changeField('Disturbi', $event, idisturbo)"
              />
          </span>
          <gnetFormField  key="2" type="picklist" title="Indicazione alla Mansione" name="Indicazione_alla_Mansione__c" 
              v-bind:options="indicazioni" v-bind:value="idoval.Indicazione_alla_Mansione__c" 
              placeholder="Indicazioni..."  v-bind:tabindex=1 v-bind:required="true"
              v-on:change="changeField('Indicazione_alla_Mansione__c', $event)"
          />          
          <gnetFormField  key="3" type="textarea" title="Note sulle restrizioni alla mansione" name="Indicazione_alla_Mansione__c" 
              v-bind:value="idoval.Note_sulle_restrizioni_alla_mansione__c" 
              placeholder="Indicazioni..."  v-bind:tabindex=2 v-bind:required="true"
              v-on:change="changeField('Note_sulle_restrizioni_alla_mansione__c', $event)"
          />

          <gnetFormField  key="4" type="picklist" title="Presenza Allergie" name="Allergie_Presenti" 
              v-bind:options="sino" v-bind:value="idoval.Allergie_Presenti" 
              placeholder="Indicazioni..."  v-bind:tabindex=1 v-bind:required="true"
              v-on:change="changeField('Allergie_Presenti', $event)"
          />          

          <span v-if="idoval.Allergie_Presenti=='Si'">
            <gnetFormField  key="5" type="textarea" title="Note sulle allergie" name="Note_sulle_allergie__c" 
                v-bind:value="idoval.Note_sulle_allergie__c" 
                placeholder="Note..."  v-bind:tabindex=2 v-bind:required="false"
                v-on:change="changeField('Note_sulle_allergie__c', $event)"
            />          
            <gnetFormField v-for="(allergia, iallergia) in allergie" v-bind:key="iallergia+10"
                type="checkbox" v-bind:title="allergia" v-bind:name="'Allergie'+(iallergia+10)" 
                v-bind:value="idoval.Allergie[iallergia]" 
                placeholder="Note..."  v-bind:tabindex=2+iallergia v-bind:required="false"
                v-on:change="changeField('Allergie', $event, iallergia)"
            />
          </span>
          
          <gnetFormField  key="6" type="picklist" title="Presenza Intolleranze" name="Intolleranze_Presenti" 
              v-bind:options="sino" v-bind:value="idoval.Intolleranze_Presenti" 
              placeholder="Indicazioni..."  v-bind:tabindex=1 v-bind:required="true"
              v-on:change="changeField('Intolleranze_Presenti', $event)"
          />          

          <span v-if="idoval.Intolleranze_Presenti=='Si'">
              <gnetFormField  key="7" type="textarea" title="Note sulle intolleranze" name="Note_sulle_intolleranze__c" 
                  v-bind:value="idoval.Note_sulle_intolleranze__c" 
                  placeholder="Note..."  v-bind:tabindex=30 v-bind:required="false"
                  v-on:change="changeField('Note_sulle_intolleranze__c', $event)"
              />

              <gnetFormField v-for="(allergia, iintolleranza) in allergie" v-bind:key="iintolleranza+30"
                  type="checkbox" v-bind:title="allergia" v-bind:name="'Intolleranze'+(iintolleranza+30)"
                  v-bind:value="idoval.Intolleranze[iintolleranza]" 
                  placeholder="Note..."  v-bind:tabindex=30+iintolleranza v-bind:required="false"
                  v-on:change="changeField('Intolleranze', $event, iintolleranza)"
              />          
          </span>
          <p class="slds-p-around_medium"></p>

    </gnetModal>

    <gnetModal v-if="showRichiesta" hspace="large" v-bind:title="'Nuova Richiesta'" v-bind:object="'document'" v-on:cancel="clearRichiesta" v-on:submit="saveRichiesta" :disabled="idoval.nameDocumento == '' || idoval.Da_caricare_entro__c == ''">
          <gnetFormField  key="1" type="text" title="Documento richiesto" name="Name" 
              v-bind:value="idoval.nameDocumento" 
              placeholder="Nome del documento richiesto..."  v-bind:tabindex=1 v-bind:required="true"
              v-on:change="changeField('nameDocumento', $event)"
          />

          <gnetFormField  key="2" type="date" title="Da caricare entro" name="Da_caricare_entro__c" 
              v-bind:value="idoval.Da_caricare_entro__c" 
              placeholder="Data di scadenza..."  v-bind:tabindex=2 v-bind:required="true"
              v-on:change="changeField('Da_caricare_entro__c', $event)"
          />

          <p class="slds-p-around_medium"></p>

    </gnetModal>

    <gnetSpinner v-bind:busy="busy"></gnetSpinner>
  
  </div>
</template>

<script>
// @ is an alias to /src
// import numeral from 'numeral'
// import apiclient from '@/apiclient.js'
import apiclient from '@/apiclient.js'

import gnetMixLib from '@/mixin.js'

import gnetHeader from '../components/header.vue'
import gnetLayout from '../components/layout.vue'
import gnetTabBar from '../components/tabbar.vue'
import gnetSpinner from '../components/spinner.vue'
import gnetModal from '../components/modal.vue'
import gnetFormField from '../components/formfield.vue'

import zTable from '../components/ztable.vue'
import zRecord from '../components/zrecord.vue'
import alert from '../components/alert.vue'

export default {
  name: 'Form',
  mixins: [gnetMixLib],
  components: {
    gnetModal,
    gnetHeader,
    gnetTabBar,
    zTable,
    zRecord,
    gnetSpinner,
    gnetLayout,
    gnetFormField,
    alert,
  },
  props: {
    objectxxx : String
  },
  data: function() {
    return {
      recordId:'',
      record:{},
      oppty:{},
      objectName: '',
      related: [],
      showheader: true,
      playbook: '',
      busy: false,
      idoShow: false,
      showRichiesta: false,
      idoFase: 0,
      headlineActions: [ 
      ],
      disturbi: [
        'Discalculia',
        'Disgrafia',
        'Dislessia',
        "Disturbi dell'attenzione"
      ],
      indicazioni: [
        {label:'Non presenta controindicazioni alla mansione', value:'Non presenta controindicazioni alla mansione'},
        {label:'Presenta controindicazioni alla mansione', value:'Presenta controindicazioni alla mansione'},
        {label:'Presenta restrizioni alla mansione', value:'Presenta restrizioni alla mansione'},
      ],
      sino: [
        {label:'Si', value:'Si'},
        {label:'No', value:'No'}
      ],
      idoval: {
        nameDocumento:'',
        Protocollo__c:'',
        Disturbi_Presenti:'',
        Disturbi:[],
        Indicazione_alla_Mansione__c:'',
        Note_sulle_restrizioni_alla_mansione__c:'',
        Allergie_Presenti:'',
        Allergie: [],
        Note_sulle_allergie__c:'',
        Intolleranze_Presenti:'',
        Intolleranze: [],
        Note_sulle_intolleranze__c:'',
        Da_caricare_entro__c:''
      },
      allergie: [
        'Cereali contenenti glutine (cioè grano, segale, orzo, avena, farro, kamut o i loro ceppiibridati) e prodotti derivati',
        'Crostacei e prodotti a base di crostacei',
        'Uova e prodotti a base di uova',
        'Pesce e prodotti a base di pesce',
        'Arachidi e prodotti a base di arachidi',
        'Soia e prodotti a basedi soia',
        'Latte e prodotti a base di latte (compreso il lattosio)',
        'Frutta a guscio e prodotti derivati',
        'Sedano e prodotti a base di sedano',
        'Senape e prodotti a base di senape',
        'Semi di sesamo e prodotti a base di semi di sesamo',
        'Anidride solforosa e solfiti',
        'Lupino e prodotti a base di lupino',
        'Molluschi e prodotti a base di molluschi'
      ],
      allDocValidati: false,
      showAlert: false,
      alertTitle: '',
      alertMessage: '',
    };
  },
  created: function() {
    var aller=new Array(this.allergie.length);
    aller.fill(false);
    this.idoval.Allergie=aller;

    var intoll=new Array(this.allergie.length);
    intoll.fill(false);
    this.idoval.Intolleranze=intoll;
  },
  mounted: function() {
    this.recordId=this.$route.params.id;
    this.navparms=this.$store.state.active_tab.params;
    this.objectName=this.navparms.object;
    this.related=this.navparms.related;
    this.getRecord().then( ()=>{
      if(this.record.Stato_Refertazione__c=='Da Refertare') {
        this.headlineActions=[  {title: 'Prima Idoneità', name:'ido1', symbol:'edit'} ];
        this.idoFase=1;
      }
      if(this.record.Stato_Refertazione__c=='Da Refertare Anamnesi') {
        this.headlineActions=[  {title: 'Seconda Idoneità', name:'ido2', symbol:'edit'} ];
        this.idoFase=2;
      }
    })
  },
  methods: {    
    setOppty: function(oppty) {
      this.oppty=oppty;
    },
    saveRichiesta: function() {
      var doc={
        Account__c: this.oppty.AccountId,
        Opportunita__c: this.recordId,
        Name: this.idoval['nameDocumento'],
        Tipo_Documento__c: 'Documento Medico',
        Stato__c:'Richiesto'
      }
      apiclient.create(this.$store.getters.connection, "Documento__c", doc).then(()=>{
        this.showRichiesta=false;
        var tmp=this.recordId;
        this.recordId='';
        this.$nextTick(()=>{
          this.recordId=tmp;
        })
      })
    },
    clearRichiesta: function() {
      this.showRichiesta=false;
    },
    nuovarichiesta: function() {
      if(this.oppty.Stato_Refertazione__c && this.oppty.Stato_Refertazione__c != '') {
        this.idoval['nameDocumento']='';
        this.showRichiesta=true;
      } else {
        this.alertTitle = 'Richiesta Non Valida'
        this.alertMessage = 'Non è possibile fare una nuova richiesta se non ci sono documenti disponibili.'
        this.showAlert = true
      }
    },
    changeField: function(fieldname, value, index) {
      console.log('changeField:'+fieldname);
      console.log('value:'+value);
      console.log('index:'+index);
      if (index>=0) {
        this.$set(this.idoval[fieldname],index,value);
      } else {
        this.idoval[fieldname]=value;
      }
    },
    saveIdo: function() {
      this.idoShow=false;
      var oppty={
        // Id: this.recordId,
        Indicazione_alla_Mansione__c: this.idoval.Indicazione_alla_Mansione__c,
        Note_sulle_restrizioni_alla_mansione__c: this.idoval.Note_sulle_restrizioni_alla_mansione__c,
        Note_sulle_allergie__c: this.idoval.Note_sulle_allergie__c,
        Note_sulle_intolleranze__c: this.idoval.Note_sulle_intolleranze__c,
      };
      var all=''
      this.idoval.Allergie.forEach((a, i)=> {
        if (a) {
          all+=this.allergie[i]+';';
        }
      })
      oppty.Allergie__c=all;

      all=''
      this.idoval.Intolleranze.forEach((a, i)=> {
        if (a) {
          all+=this.allergie[i]+';';
        }
      })
      oppty.Intolleranze__c=all;
      if (this.idoFase==1) {
        oppty.Protocollo_1__c=this.idoval.Protocollo__c;
        oppty.Stato_Refertazione__c='Refertato';
      }
      if (this.idoFase==2) {
        oppty.Protocollo_2__c=this.idoval.Protocollo__c;
        oppty.Stato_Refertazione__c='Referto Completato';
      }
      console.log(oppty);
      this.saveRecord(oppty).then( (data) => {
        console.log(data);
        this.busy=false;
        this.$router.push( { path: '/idoneita/' } );
      });
    },
    getRecord: function() {
      var self=this;
      return new Promise( (resolve, reject) => {
        var fields=[
        'Protocollo_1__c', 
        'Protocollo_2__c', 
        'Stato_Refertazione__c', 
        'Disturbi_dell_apprendimento__c',
        'Indicazione_alla_Mansione__c', 
        'Note_sulle_restrizioni_alla_mansione__c', 
        'Allergie__c',
        'Note_sulle_allergie__c', 
        'Intolleranze__c',
        'Note_sulle_intolleranze__c'
        ]
        var soql='select id,'+fields.join(',')+' from Opportunity where id=\''+self.recordId+'\'';
        console.log(soql);
        apiclient.query(self.$store.getters.connection, soql).then(function(data) {
          console.log('records:'+data.length);
          console.log(data)
          if (data.length>0) {
            console.log('set idoval');
            self.record=data[0];
            self.setFields();
            console.log(self.idoval);
          }
          resolve();
        }).catch(function(err){
          console.log(err);
          reject(err);
        })
      })
    },
    setFields: function() {
      this.idoval['Disturbi_dell_apprendimento__c']=this.record['Disturbi_dell_apprendimento__c'];
      this.idoval['Indicazione_alla_Mansione__c']=this.record['Indicazione_alla_Mansione__c'];
      this.idoval['Note_sulle_restrizioni_alla_mansione__c']=this.record['Note_sulle_restrizioni_alla_mansione__c'];
      var d=this.record['Disturbi_dell_apprendimento__c'];
      if (d) {
        this.idoval.Disturbi=d.split(';');
        this.idoval.Disturbi_Presenti='Si';
      } else {
        this.idoval.Disturbi_Presenti='No';
      }
      var a=this.record['Allergie__c'];
      if (a) {
        this.idoval.Allergie=a.split(';');
        this.idoval.Allergie_Presenti='Si';
      } else {
        this.idoval.Allergie_Presenti='No';
      }
      var i=this.record['Intolleranze__c'];
      if (i) {
        this.idoval.Intolleranze=i.split(';');
        this.idoval.Intolleranze_Presenti='Si';
      } else {
        this.idoval.Intolleranze_Presenti='No';
      }
      this.idoval['Note_sulle_allergie__c']=this.record['Note_sulle_allergie__c'];
      this.idoval['Note_sulle_intolleranze__c']=this.record['Note_sulle_intolleranze__c'];
    },
    saveRecord: function(oppty) {
      console.log('saveRecord')
      this.busy=true;
      return apiclient.update(this.$store.getters.connection, "Opportunity", this.recordId, oppty);
    },
    clearIdo: function() {
      this.idoShow=false;
      this.showAlert = false
    },
    SetAllDocValidati() { this.allDocValidati = true },
    ido1: function() {
      console.log('ido1');
      // this.idoShow=true;
      if(this.allDocValidati) this.idoShow=true;
      else {
        this.alertTitle = 'Validazione Incompleta'
        this.alertMessage = 'È necessario validare tutti i documenti per poter accedere a questa parte.'
        this.showAlert = true
      }
    },
    ido2: function() {
      console.log('ido2');
      this.idoShow=true;
    },
    showdocument: function(event) {
      console.log('showdocument');
      console.log(event);
      // alert('totp required')
      this.$router.push( { path: '/document/'+event.Id } );
    },
  }
}
</script>
<style scoped>
</style>

