<template lang="html">

  <div class="slds-form" role="list">


    <gnetExpandable v-on:click="$emit('click',isection)" v-for="(section,isection) in sections" v-bind:key="section.order" v-bind:title="section.label" v-bind:opened='section.opened'>

        <div class="slds-form__row" v-for="riga in Math.floor(section.fields.length/2)+1" v-bind:key="riga">

          <div class="slds-form__item" role="listitem" v-for="colonna in 2" v-bind:key="colonna">

            <gnetFormField v-if="!field(section.fields,riga,colonna).hide" 
              v-bind:key="field(section.fields,riga,colonna).id" 
              v-bind:title="field(section.fields,riga,colonna).title" 
              v-bind:type="field(section.fields,riga,colonna).type" 
              v-bind:name="field(section.fields,riga,colonna).name" 
              v-bind:options="field(section.fields,riga,colonna).options"
              v-bind:value="field(section.fields,riga,colonna).value" 
              v-bind:placeholder="field(section.fields,riga,colonna).placeholder" 
              v-bind:tabindex="field(section.fields,riga,colonna).tabindex"
              v-on:change="changeField(isection, (riga-1)*2+colonna-1, $event)"
              v-bind:disabled="readonly || field(section.fields,riga,colonna).disabled"
              v-bind:required="field(section.fields,riga,colonna).required"
               />
          </div>
          
        </div>


<!--
      <span v-for="(f, index) in evenFields(section.fields)" v-bind:key="f.id">
        <div class="slds-form__row">

          <div class="slds-form__item" role="listitem">

            <gnetFormField v-bind:key="f.id" v-bind:title="f.title" v-bind:type="f.type" v-bind:name="f.name" v-bind:options="f.options"
              v-bind:value="f.value" v-bind:placeholder="f.placeholder" v-bind:tabindex="f.tabindex" v-on:change="$emit('change', { name:f.name, value:$event} )"
              v-bind:disabled="f.disabled" />

          </div>

          <div class="slds-form__item" role="listitem">

            <gnetFormField v-if="nextFields(section.fields,index)" v-bind:key="nextFields(section.fields,index).id"
              v-bind:title="nextFields(section.fields,index).title" v-bind:type="nextFields(section.fields,index).type"
              v-bind:name="nextFields(section.fields,index).name" v-bind:value="nextFields(section.fields,index).value" v-bind:options="nextFields(section.fields,index).options"
              v-bind:placeholder="nextFields(section.fields,index).placeholder" v-on:change="$emit('change', { name: nextFields(section.fields,index).name, value:$event} )"
              v-bind:tabindex="nextFields(section.fields,index).tabindex"
              v-bind:disabled="nextFields(section.fields,index).disabled" />
          </div>

        </div>
      </span>
-->

    </gnetExpandable>



  </div>

</template>

<script>
import gnetFormField from '../components/formfield.vue'
import gnetExpandable from '../components/expandable.vue'

export default {
  name: 'gnetFormMask',
  components: {
    gnetFormField,
    gnetExpandable
  },
  props: {
    sections: Array,
    readonly: Boolean
  },
  data() {
    return {
    }
  },
  async created() {
  },
  mounted: function() {
  },
  computed: {
  },
  methods: {
    field:function(fields, r, c) {
      var i=(r-1)*2+c-1;
      if (fields.length>i) return fields[i];
      return {};
    },
    changeField: function(s, f, v) {
      this.$emit('change', { section: s, field:f, value: v} )
    },
    evenFields: function (fields) {
      return fields.filter(function (f,index) {
        return index % 2 === 0
      })
    },
    nextFields: function (fields, index) {
      var v=parseInt(index);
      var v1=((v)*2)+1;
      if (fields.length>v1)
        return fields[v1];
      return undefined;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
