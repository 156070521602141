<template lang="html">

  <div>
    <gnetRecordHeader v-bind:object="object.icon" v-bind:title="$t(object.label)" 
        v-bind:actions="headlineActions" v-on:click="actionClick($event)"/>
    <div class="slds-page-header" style="background-color: white;">
      <gnetFormMask v-bind:large="true" v-on:click="toggleSection(sections,$event)" v-bind:sections="sections" 
        v-on:change="setField(sections,$event); setRecordField(sections,$event); durty=true;" 
        v-on:remove="remove(sections,$event); durty=true" 
        v-on:add="add(sections,$event); durty=true">
      </gnetFormMask>
    </div>        
    <gnetSpinner v-bind:busy="busy"></gnetSpinner>
  </div>    
</template>

<script>
import apiclient from '@/apiclient.js'
import gnetMixLib from '@/mixin.js'

import gnetRecordHeader from '../components/recordheader.vue'
import gnetFormMask from '../components/formmask.vue'

import gnetSpinner from '../components/spinner.vue'


export default {
  name: 'zRecord',
  mixins: [gnetMixLib],
  components: {
    gnetRecordHeader,
    gnetFormMask,
    gnetSpinner
  },
  computed: {
  },
  props: {
    recordId: String,
    objectName: String,
    headlineActions: Array
  },
  data() {
    return {
      record: {},
      viewForm: 0,
      editForm: 1,
      actualForm: -1,
      busy: false,
      durty: false,
      object: {},
      sections: [],
      fields:[],
      // headlineFields: [],
      // headlineActions: [ {title: 'Modifica', name:'edit', symbol:'edit'}, ],
    }
  },
  mounted: function() {
  },
  async created() {

  },
  watch: {
    objectName: function() {
      this.fetchData();
    },
    recordId: function() {
      var self=this;
      this.fetchData(true).then(function() {
        self.getRecord();
      })
    },
  },
  methods: {
    actionClick: function(e) {
      this.$emit(e);
      /*
      switch (e) {
        case 'edit':
          this.modifica();
        break;
        case 'save':
          this.saveRecord();
        break;
        case 'cancel':
          var self=this;
          self.getRecord().then(function() {
            self.visualizza();
          })
        break;
      }
      */
    },
    modifica: function() {
      this.headlineActions.pop();
      this.headlineActions.push({title: 'Salva', name:'save', symbol:'save'});
      this.headlineActions.push({title: 'Annulla', name:'cancel', symbol:'close'});
      this.showEdit=true;
      this.viewChange(this.editForm);
      this.fetchData(false);
    },
    visualizza: function() {
      console.log('visualizza')
      this.headlineActions.pop();
      this.headlineActions.pop();
      this.headlineActions.push({title: 'Modifica', name:'edit', symbol:'edit'});
      this.showEdit=false;
      this.viewChange(this.viewForm);
      this.fetchData(true);
    },
    fetchData: function(readonly) {
      var self=this;
      return new Promise(function(resolve, reject) {
        // self.busy=true;
        self.$store.dispatch('getObject', self.objectName).then(function(obj) {
          console.log('getObject');
          console.log(obj);
          self.object=obj;
          if (self.actualForm<0) {
            self.viewChange(self.object.dform);
          }
          self.computeFields(self.actualForm, readonly);
          // self.busy=false;
          resolve();
        }).catch(function(err) {
          reject(err);
        })
      })
    },
    viewChange: function(form) {
      this.actualForm=form;
      this.sections=this.object.forms[form].sections;
    },
    saveRecord: function() {
      console.log('saveRecord')
      var mydata=this.fixObject();
      console.log(mydata);
      this.busy=false;
      var self=this;
      apiclient.update(this.$store.getters.connection, this.objectName, this.recordId, mydata).then(function(data) {
        self.busy=false;
        console.log(data);
        self.getRecord().then(function() {
          self.visualizza();
        })
      }).catch(function(err){
        self.busy=false;
        console.log(err);
      })
    },
    getRecord: function() {
      var self=this;
      return new Promise(function(resolve, reject) {
        var soql='select '+self.fieldsstring+' from '+self.objectName+' where id=\''+self.recordId+'\'';
        console.log('zrecord.getRecord --> soql',soql);
        apiclient.query(self.$store.getters.connection, soql).then(function(data) {
          console.log('records:'+data.length);
          console.log(data)
          if (data.length>0) {
            self.record=data[0];
            self.$emit('ready',self.record);
          }
          self.updateRecordFields(self.sections);
          self.busy=false;
          resolve();
        }).catch(function(err){
          console.log(err);
          reject(err);
        })
      })
    },
    getForm: function(form) {
      if (!form) return this.object.forms[this.object.dform];
      return this.object.forms[form];
    },
    setRecordField: function(sections,e) {
        var s=sections[e.section];
        var f=s.fields[e.field];
        var v=e.value;
        console.log(f.name+'='+v);
        this.record[f.name]=v;
    },
    fixObject:function() {
      var r={};
      this.fields.forEach(function(f) {
        // r[f.name].value=this.record[f.name];
        console.log(f);
        var v=this.record[f.name];
        if (v)
          r[f.name]=v;
      }, this)
      // r.Id=this.record.Id;
      return r;
    },
    computeFields:function(form, readonly) {
      this.fields.splice(0,this.fields.length);
      var f=this.getForm(form);
      console.log('f: ',f)
      var mfields=new Map();
      mfields.set('Id', null);
      console.log('computeFields');
      f.sections.forEach(function(s) {
        s.fields.forEach(function(c) {
          var f=this.object.fields[ this.object.mfields[c.name] ];
          console.log('f:',f);
          this.fields.push({ order: c.order, value:'', label:f.label, name:f.name, type:f.type, disabled: c.disabled || readonly } );
          this.$set(c,'title',f.label);
          this.$set(c,'type',f.type);
          this.$set(c,'disabled', c.disabled || readonly);
          //per disabilitare
          this.$set(c,'value', '');
          if (this.record && this.record[f.name])
            this.$set(c,'value', this.record[f.name]);
          mfields.set(f.name, null);
        }, this )
      }, this )

      this.fieldsstring=this.uniqueFields(mfields).join(',');
    }, 

  }
}
</script>

<style scoped>
</style>
