<template lang="html">

    <div v-if="type!='hidden'" class="slds-form-element slds-form-element_stacked" v-bind:class="{ 'slds-form-element_readonly': disabled, 'slds-has-error': (errormsg || (required && !value))  }">
      
      <div v-if="type=='null'">
      </div>

      <div v-if="disabled && type!='null'">
        <span class="slds-form-element__label">{{title}}</span>
          <div class="slds-form-element__control">
            <div class="slds-form-element__static">
              <span v-if="type=='text' || type=='truncate' || type=='string' || type=='picklist' || type=='multipicklist' " v-html="value"></span>
              <span v-if="type=='number' || type=='currency' || type=='decimal' || type=='date' || type=='datetime'">{{value | filter(type)}}</span>
            </div>
          </div>
      </div>

      <div v-if="!disabled && type!='null'">

        <label v-if=" type!='checkbox' && type!='picklist' && type!='multipicklist' " class="slds-form-element__label" v-bind:for="'form-element-'+tabindex"><abbr v-if="required" class="slds-required" title="required">* </abbr>{{title}}</label>

        <div v-if="type=='text' || type=='truncate' || type=='number' || type=='currency' " class="slds-form-element__control">
          <input v-on:change="$emit('change',$event.target.value)" v-bind:value="value" v-bind:type="type" v-bind:id="'form-element-'+tabindex" v-bind:placeholder="placeholder" class="slds-input" />
        </div>

        <div v-if="type=='textarea' " class="slds-form-element__control">
          <textarea v-on:change="$emit('change',$event.target.value)" v-bind:value="value" v-bind:type="type" v-bind:id="'form-element-'+tabindex" v-bind:placeholder="placeholder" class="slds-input" />
        </div>

        <div v-if="type=='checkbox' " >
          <fieldset class="slds-form-element">
              <legend v-if="false" class="slds-form-element__legend slds-form-element__label">{{title}}</legend>
              <div class="slds-form-element__control">
                <div v-on:click="$emit('change', !value)" class="slds-checkbox">
                    <input v-bind:value="value" v-bind:checked="value" v-bind:type="type" v-bind:id="'form-element-'+tabindex"  />
                    <label class="slds-checkbox__label" for="checkbox-103">
                        <span class="slds-checkbox_faux"></span>
                        <span class="slds-form-element__label">{{title}}</span>
                    </label>
                </div>
              </div>
          </fieldset>
        </div>

        <div v-if="type=='picklist' || type=='multipicklist' " >
          <formpicklist
            v-bind:id="id"
            v-bind:title="title"
            v-bind:type="type"
            v-bind:name="name"
            v-bind:value="value"
            v-bind:placeholder="placeholder"
            v-bind:tabindex="tabindex"
            v-bind:disabled="disabled"
            v-bind:options="options"
            v-bind:hform="hform"
            v-on:change="$emit('change', $event)"
            />
        </div>

        <div v-if="type=='date' " class="slds-form-element__control">
          <input v-on:change="$emit('change',$event.target.value)" v-bind:value="value" v-bind:type="type" v-bind:id="'form-element-'+tabindex" v-bind:placeholder="placeholder" class="slds-input" />
        </div>

        <div class="slds-form-element__help" v-if="errormsg">{{errormsg}}</div>
      </div>
    </div>

</template>

<script>
import formpicklist from './picklist.vue'
// import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
// import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

import numeral from 'numeral'
import moment from 'moment'

  export default {
    name: 'gnetFormField',
    components: {
      formpicklist,
//      VueCtkDateTimePicker
     },
    props: {
      id: String,
      name: String,
      value: [String, Number, Date, Boolean],
      title: String,
      type: String,
      tabindex: Number,
      disabled: Boolean,
      placeholder: String,
      options: Array,
      hform: Boolean,
      errormsg: String,
      required: Boolean
    },
    filters: {
      filter: function(v, type) {
        switch(type) {
          case 'date':
            if (v) {
              var n=new moment(v);
              return n.format('DD/MM/YYYY');
            }
            return v;
          case 'datetime':
            if (v) {
              var d = new moment(v)
              return d.format('DD/MM/YYYY, HH:MM')
            }
            return v
          case 'decimal':
            if (v)
              return numeral(v).format('0,0');
            return v;
          case 'number':
            return v;
          case 'currency':
            if (v)
              return numeral(v).format('$ 0,0.00');
            return v;
            /*
          case 'alert':
              if (!v)
                return '';
              return '';
          case 'truncate':
            if (v)
              return v.substr(0,32);
            return '';
            */
          /*
          case 'options':
            console.log('options');
            var i=col.options.findIndex(function(e) {
              if (e.value==v) return true;
            });
            console.log('options:'+i);
            if (i>=0) return col.options[i].label;
            return v;
          */
        }
        return v;
      }

    },
    data() {
      return {}
    },
    computed: {},
    async created() {},
    methods: {}
  }

</script>

<style scoped>

</style>
