<template lang="html">

  <div>
    <div v-if="top" class="slds-grid slds-gutters_xxx-small">
      <div class="slds-col slds-size_3-of-3">
        <slot name="top"></slot>
      </div>
    </div>
    <div v-if="shape=='111'" class="slds-grid slds-gutters_xxx-small">
      <div class="slds-col slds-size_1-of-3">
        <slot name="left"></slot>
      </div>
      <div class="slds-col slds-size_1-of-3">
        <slot name="center"></slot>
      </div>
      <div class="slds-col slds-size_1-of-3">
        <slot name="right"></slot>
      </div>
    </div>
    <div v-if="shape=='21'" class="slds-grid slds-gutters_xxx-small">
      <div class="slds-col slds-size_2-of-3">
        <slot name="left"></slot>
      </div>
      <div class="slds-col slds-size_1-of-3">
        <slot name="right"></slot>
      </div>
    </div>    
    <div v-if="shape=='11'" class="slds-grid slds-gutters_xxx-small">
      <div class="slds-col slds-size_1-of-2">
        <slot name="left"></slot>
      </div>
      <div class="slds-col slds-size_1-of-2">
        <slot name="right"></slot>
      </div>
    </div>    
  </div>    
</template>

<script>

export default {
  name: 'gnetLayout',
  components: {
  },
  computed: {
  },
  props: {
    shape: String,
    top: Boolean
  },
  data() {
    return {
    }
  },
  mounted: function() {
  },
  async created() {

  },
  methods: {
  }
}
</script>

<style scoped>
</style>
